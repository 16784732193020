import React, { Component } from 'react'
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom'; //npm install --save react-router-dom
import { post } from 'axios'; // npm install --save axios
import { getRequestParams } from '../helpers/globalfunctions.js';
import Swal from 'sweetalert2'; //npm i sweetalert2
import NumberFormat from 'react-number-format'; //npm install react-number-format --save
import { LoadPanel } from 'devextreme-react/load-panel';
import HtmlEditor, { Toolbar, Item } from 'devextreme-react/html-editor';
const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
export const priorityDropdownLis = [{
    'id': 1,
    'name': 'Normal',
}, {
    'id': 2,
    'name': 'Moderate',
}, {
    'id': 3,
    'name': 'High',
}]
export class CreateNewTicketComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmitted: false,
            isRefreshSubmitted: false,
            isTicketCreated: false,
            ticketCreatedMsg: '',
            loadPanelVisible: false,
            helpTopicDropdownList: [],
            helpTopicDropdownData: null,
            helpTopicId: 0,
            userFirstName: '',
            userLastName: '',
            userEmail: '',
            userMobile: '',
            countryCode: '',
            priorityId: 1,
            issueSummary: '',
            issueDetails: '',
            attachments: [],
            errors: {
                helpTopic: '',
                userFirstName: '',
                userEmail: '',
                userMobile: '',
                countryCode: '',
                issueSummary: '',
                issueDetails: '',
            }
        }
    }
    async componentDidMount() {
        await this.getHelptopicDropdownList();
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmitted: true, loadPanelVisible: true });
            this.setState({
                isSubmitted: false,
                isTicketCreated: false,
                ticketCreatedMsg: '',
                helpTopicDropdownList: [],
                helpTopicDropdownData: null,
                helpTopicId: 0,
                userFirstName: '',
                userLastName: '',
                userEmail: '',
                userMobile: '',
                countryCode: '',
                priorityId: 1,
                issueSummary: '',
                issueDetails: '',
                attachments: [],
                errors: {
                    helpTopic: '',
                    userFirstName: '',
                    userEmail: '',
                    userMobile: '',
                    countryCode: '',
                    issueSummary: '',
                    issueDetails: '',
                }
            });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            await this.getHelptopicDropdownList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmitted: false, loadPanelVisible: false });
    }
    // Start add new ticket
    getHelptopicDropdownList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            const requestParams = getRequestParams('GET');
            const response = await fetch('ticketing-api/helptopic-dropdown', requestParams);
            const data = await response.json();
            this.setState({
                helpTopicDropdownList: data.helpTopics
            });
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    handleOnInputChange = (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'userFirstName':
                    errors.userFirstName = '';
                    if (value.length <= 50) {
                        this.setState({ userFirstName: value });
                    }
                    break;
                case 'userLastName':
                    if (value.length <= 50) {
                        this.setState({ userLastName: value });
                    }
                    break;
                case 'userEmail':
                    errors.userEmail = '';
                    if (value.length <= 50) {
                        this.setState({ userEmail: value });
                    }
                    break;
                case 'issueSummary':
                    errors.issueSummary = '';
                    if (value.length < 76) {
                        this.setState({ issueSummary: value });
                    }
                    break;
                case 'ddhelptopic':
                    errors.helpTopic = '';
                    this.setState({ helpTopicId: value });
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnFileSelect = async (event) => {
        try {
            this.setState({ loadPanelVisible: true });
            let attachments = this.state.attachments;
            //let fileCount = attachments.length + event.target.files.length;
            //if (fileCount > 5) {
            //    await this.onfailure('error', 'Max file limit', 'Please select no more than 5 files.');
            //}
            //else
            if (event.target.files.length > 0) {
                let isFilesInvalid = false;
                let documentSelectedFile = event.target.files;
                const formData = new FormData();
                for (var i = 0; i < documentSelectedFile.length; i++) {
                    let file = documentSelectedFile[i];
                    if (file.size > 0) {
                        formData.append('File', file);
                    }
                    else {
                        isFilesInvalid = true;
                        await this.onfailure('error', 'Invalid file', 'The file ' + file.name + ' found invalid.');
                        break;
                    }
                }
                if (isFilesInvalid === false) {
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data',
                            'UserId': sessionStorage.getItem('session_userId'),
                            'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                            'CultureCode': sessionStorage.getItem('session_culturecode'),
                        },
                    };
                    const response = await post('ticketing-api/add-attachment', formData, config);
                    if (response.status === 200) {
                        const data = await response.data.attachments;
                        if (data !== null) {
                            let maxFileId = 0;
                            if (attachments !== null && attachments.length > 0) {
                                maxFileId = Math.max.apply(Math, attachments.map(function (val) { return val.id; }))
                            }
                            for (var a = 0; a < data.length; a++) {
                                maxFileId = Number(maxFileId) + 1;
                                let attachment = {};
                                attachment.id = maxFileId;
                                attachment.fileName = data[a].fileName;
                                attachment.fileData = data[a].fileData;
                                attachment.fileSize = data[a].fileSize;
                                attachment.fileExtension = data[a].fileExtension;
                                attachments.push(attachment);
                                this.setState({ attachments: attachments });
                            }
                        }
                    }
                }
            }
            else {
                await this.onfailure('error', 'Invalid file', 'Please select file again.');
            }
            this.fileInput.value = "";
        } catch (ex) {
            await this.onfailure('error', null, null);
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    removeAttachmante = async (e, id) => {
        e.preventDefault();
        try {
            let attachments = this.state.attachments;
            attachments = attachments.filter(r => r.id !== id);
            this.setState({ attachments: attachments });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSaveNewTicketSubmitted = async event => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                let request = {};
                request.HelpTopicId = Number(this.state.helpTopicId);
                request.UserFirstName = this.state.userFirstName.trim();
                request.UserLastName = this.state.userLastName.trim();
                request.UserEmail = this.state.userEmail.trim();
                request.UserMobile = this.state.userMobile.trim();
                request.CountryCode = this.state.countryCode.trim();
                request.IssuePriorityId = Number(this.state.priorityId);
                request.IssueSummary = this.state.issueSummary.trim();
                request.IssueDetails = this.state.issueDetails.trim();
                request.Attachments = this.state.attachments;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('ticketing-api', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        html: data.isSuccess === false ? 'Opps! something went wrong, unable to process the request.' : data.resultMessage,
                    }).then(async (result) => {
                        if (data.isSuccess) {
                            this.setState({ isTicketCreated: true, ticketCreatedMsg: data.resultMessage });
                            if (result.isConfirmed) {
                            }
                        }
                    })
                }
                else {
                    await this.onfailure('error', 'Failed!', null);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    handleOnClearNewTicket = async (event) => {
        event.preventDefault();
        try {
            let errors = this.state.errors;
            errors.helpTopic = '';
            errors.userFirstName = '';
            errors.userEmail = '';
            errors.userMobile = '';
            errors.countryCode = '';
            errors.issueSummary = '';
            errors.issueDetails = '';
            this.setState({
                isSubmitted: false,
                isRefreshSubmitted: false,
                isTicketCreated: false,
                ticketCreatedMsg: '',
                loadPanelVisible: false,
                helpTopicDropdownData: null,
                helpTopicId: 0,
                userFirstName: '',
                userLastName: '',
                userEmail: '',
                userMobile: '',
                countryCode: '',
                priorityId: 1,
                issueSummary: '',
                issueDetails: '',
                attachments: [],
                errors: errors
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnHelpTopicDropdownChange = (data) => {
        try {
            let errors = this.state.errors;
            errors.helpTopic = '';
            let helpTopicId = 0;
            if (data !== null) {
                helpTopicId = (data !== null ? data.id : 0);
            }
            else {
                //errors.helpTopic = 'Please select help topic.';
            }
            this.setState({ helpTopicDropdownData: data, helpTopicId: helpTopicId, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnIssueBodyChange = (e) => {
        try {
            let errors = this.state.errors;
            errors.issueDetails = '';
            this.setState({ issueDetails: e.value, errors: errors })
        } catch (ex) {
            console.log(ex);
        }
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.helpTopic = '';
            errors.userFirstName = '';
            errors.userEmail = '';
            errors.userMobile = '';
            errors.countryCode = '';
            errors.issueSummary = '';
            errors.issueDetails = '';
            let regEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (Number(this.state.helpTopicId) === 0) {
                errors.helpTopic = 'Please select help topic.';
                isValid = false;
            }
            if (this.state.userFirstName.trim() === '') {
                errors.userFirstName = 'Please enter first name.';
                isValid = false;
            }
            if (this.state.userEmail.trim() === '') {
                isValid = false;
                errors.userEmail = "Please enter email.";
            }
            if (this.state.userEmail.trim() !== '') {
                if (!regEmail.test(String(this.state.userEmail.trim()).toLowerCase())) {
                    isValid = false;
                    errors.userEmail = "Please enter valid email.";
                }
            }
            if (this.state.countryCode.trim() !== '') {
                if (this.state.userMobile.trim() === '') {
                    isValid = false;
                    errors.userMobile = 'Please enter mobile no.';
                }
            }
            if (this.state.userMobile.trim() !== '') {
                if (this.state.countryCode.trim() === '') {
                    isValid = false;
                    errors.countryCode = 'Please enter country code.';
                }
                if (this.state.userMobile.trim().length !== 10) {
                    isValid = false;
                    errors.userMobile = 'Please enter valid mobile no.';
                }
            }
            if (this.state.issueSummary.trim() === '') {
                isValid = false;
                errors.issueSummary = "Please enter issue summary.";
            }
            //if (this.state.issueDetails.getEditorState().getCurrentContent().getPlainText().trim() === '') {
            if (this.removeHtmlTags(this.state.issueDetails.trim()).trim() === '') {
                isValid = false;
                errors.issueDetails = "Please enter issue details.";
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    removeHtmlTags = (str) => {
        try {
            if ((str === null) || (str === ''))
                return '';
            else
                str = str.toString();
            // Regular expression to identify HTML tags in 
            // the input string. Replacing the identified 
            // HTML tag with a null string.
            return str.replace(/(<([^>]+)>)/ig, '');
        } catch (ex) {
            console.log(ex);
            return '';
        }
    }
    // End add new ticket
    onfailure = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                <h1 className="m-0 text-dark">Open a New Ticket</h1>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" title="Refresh"
                                            disabled={(this.state.isSubmitted || this.state.isTicketCreated)} onClick={this.handleOnRefresh}>
                                            {this.state.isRefreshSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isRefreshSubmitted && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                        </NavLink>
                                    </NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleOnSaveNewTicketSubmitted}
                    style={(this.state.isSubmitted || this.state.isRefreshSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="rez-header">
                        <div className="container">
                            <div className="content dashboard tickitcreat">
                                <div className="card">
                                    <div class="card-header tickitheader p-2">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-left">
                                                <div className="form-group mt-1">
                                                    <a href="/"><img src="../../dist/img/logo_utwiz.png" alt="UTWiz Ticketing" className="brandlogosm" /></a>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 text-right" >
                                                <p className="login-box-msg pb-0 text-white text-right pt-1">Please fill in the form below to open a new ticket.</p>
                                                <p className="login-box-msg text-white text-right pb-0" style={{ display: (this.state.isTicketCreated) || 'none' }}>
                                                    {this.state.isTicketCreated === true && <span className='text-green'>{this.state.ticketCreatedMsg}</span>}&nbsp;&nbsp;
                                                    {this.state.isTicketCreated === true && <a href="/login" title="Sign In">Sign In</a>}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Help Topic <span className="text-danger">*</span></label>
                                                    {/*<Select
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Select help topic"
                                                        //defaultValue={[]}
                                                        value={this.state.helpTopicDropdownData}
                                                        name="ddhelptopic"
                                                        onChange={this.handleOnHelpTopicDropdownChange}
                                                        getOptionLabel={option => option.name}
                                                        getOptionValue={option => option.id}
                                                        options={this.state.helpTopicDropdownList}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                    />*/}
                                                    <select name="ddhelptopic" className="form-control form-control-sm"
                                                        value={this.state.helpTopicId} onChange={this.handleOnInputChange}>
                                                        <option value="0">---Select help topic---</option>
                                                        {
                                                            this.state.helpTopicDropdownList &&
                                                            this.state.helpTopicDropdownList.map((item, i) =>
                                                                (<option key={i} value={item.id}>{item.name}</option>))
                                                        }
                                                    </select>
                                                    {this.state.errors["helpTopic"].length > 0 && <span className='error invalid-feedback'>
                                                        {this.state.errors["helpTopic"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>First Name <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm"
                                                        name="userFirstName"
                                                        placeholder="First name"
                                                        title="First Name"
                                                        value={this.state.userFirstName}
                                                        onChange={this.handleOnInputChange} />
                                                    {this.state.errors["userFirstName"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userFirstName"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input type="text" className="form-control form-control-sm"
                                                        name="userLastName"
                                                        placeholder="Last name"
                                                        title="Last Name"
                                                        value={this.state.userLastName}
                                                        onChange={this.handleOnInputChange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Email <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm"
                                                        name="userEmail"
                                                        placeholder="example@domain.com"
                                                        title="Email"
                                                        value={this.state.userEmail}
                                                        onChange={this.handleOnInputChange} />
                                                    {this.state.errors["userEmail"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userEmail"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                                <div className="form-group">
                                                    <label>Code</label>
                                                    <NumberFormat className="form-control form-control-sm" name="countryCode" title="Country Code" format="+##" value={this.state.countryCode} onValueChange={(e) => {
                                                        let err = this.state.errors;
                                                        err.countryCode = '';
                                                        this.setState({ countryCode: e.value, errors: err });
                                                    }} placeholder="" />
                                                    {this.state.errors["countryCode"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["countryCode"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <div className="form-group">
                                                    <label>Mobile Number</label>
                                                    <NumberFormat className="form-control form-control-sm" name="userMobile" title="Mobile Number"
                                                        placeholder="(000) 000-0000" format="(###) ###-####" value={this.state.userMobile}
                                                        onValueChange={(e) => {
                                                            let err = this.state.errors;
                                                            err.userMobile = '';
                                                            this.setState({ userMobile: e.value, errors: err });
                                                        }} />
                                                    {this.state.errors["userMobile"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userMobile"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                                <div className="form-group">
                                                    <label>Priority <span className="text-danger">*</span></label>
                                                    <select name="ddpriority" className="form-control form-control-sm"
                                                        value={this.state.priorityId} onChange={(e) => { this.setState({ priorityId: e.target.value }) }}>
                                                        {
                                                            priorityDropdownLis &&
                                                            priorityDropdownLis.map((item, i) =>
                                                                (<option key={i} value={item.id}>{item.name}</option>))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Issue Summary <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control form-control-sm"
                                                        name="issueSummary"
                                                        placeholder=""
                                                        title="Issue Summary"
                                                        value={this.state.issueSummary}
                                                        onChange={this.handleOnInputChange} />
                                                    {this.state.errors["issueSummary"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["issueSummary"]}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label>Issue Details <span className="text-danger">*</span></label>
                                                    <HtmlEditor
                                                        height={160}
                                                        name="issuedetails"
                                                        value={this.state.issueDetails}
                                                        valueType={"html"}
                                                        onValueChanged={this.handleOnIssueBodyChange}
                                                    >
                                                        <Toolbar>
                                                            <Item name="undo" />
                                                            <Item name="redo" />
                                                            <Item name="separator" />
                                                            <Item
                                                                name="size"
                                                                acceptedValues={sizeValues}
                                                            />
                                                            <Item
                                                                name="font"
                                                                acceptedValues={fontValues}
                                                            />
                                                            <Item name="separator" />
                                                            <Item name="bold" />
                                                            <Item name="italic" />
                                                            <Item name="strike" />
                                                            <Item name="underline" />
                                                            <Item name="separator" />
                                                            <Item name="alignLeft" />
                                                            <Item name="alignCenter" />
                                                            <Item name="alignRight" />
                                                            <Item name="alignJustify" />
                                                            <Item name="separator" />
                                                            <Item name="color" />
                                                            <Item name="background" />
                                                        </Toolbar>
                                                    </HtmlEditor>
                                                    {this.state.errors["issueDetails"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["issueDetails"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <div className="custom-file-upload">
                                                        <button type="button"
                                                            className="btn btn-secondary btn-sm"
                                                            onClick={() => { this.fileInput.click() }}
                                                        ><i class="fas fa-paperclip"></i>&nbsp;&nbsp;Choose File</button>&nbsp;&nbsp;
                                                        <span>
                                                            {
                                                                this.state.attachments.length === 0 ? 'No file attached' : this.state.attachments.length + ' file(s) attached'
                                                            }
                                                        </span>
                                                        <input type="file" className="custom-file-input"
                                                            ref={ref => this.fileInput = ref}
                                                            name="Files" multiple
                                                            style={{ display: 'none' }}
                                                            onChange={(e) => { this.handleOnFileSelect(e, false) }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    {
                                                        this.state.attachments &&
                                                        this.state.attachments.map((item, i) =>
                                                        (<label style={{ marginRight: '2px' }}
                                                            key={i}>{item.fileName}
                                                            <NavLink onClick={(e) => { this.removeAttachmante(e, item.id) }}>
                                                                <span style={
                                                                    {
                                                                        color: 'red', fontSize: '20px', cursor: 'pointer'
                                                                        , marginRight: '6px', position: 'relative', top: '-8px'
                                                                    }}>
                                                                    ×</span>
                                                            </NavLink>
                                                        </label>
                                                        )
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right" >
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-success btn-sm"
                                                        title="Save Ticket"
                                                        disabled={this.state.isTicketCreated}>
                                                        {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                        {!this.state.isSubmitted && <span title="Save Ticket"><i className="fas fa-check"></i> Save Ticket</span>}
                                                    </button>&nbsp;&nbsp;
                                                    <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                                        onClick={this.handleOnClearNewTicket}>
                                                        <i className="fas fa-eraser"></i> Clear
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        );
    }
}