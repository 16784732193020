import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom'; //npm install --save react-router-dom
import {
    Chart,
    Series,
    CommonSeriesSettings,
    Legend,
    Label,
    SeriesTemplate,
} from 'devextreme-react/chart';
import {
    PieChart,
    Connector,
} from 'devextreme-react/pie-chart';
import { getRequestParams } from './helpers/globalfunctions.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { DateBox } from 'devextreme-react/date-box';
import moment from 'moment/min/moment-with-locales';//npm install --save moment react-moment
let currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
export const ticketSummaryList = [{
    issueStatus: 'Open',
    ticketDate: '21/08/2021',
    resolutionDate: '',
    ticketCount: 3,
}, {
    issueStatus: 'Open',
    ticketDate: '22/08/2021',
    resolutionDate: '',
    ticketCount: 2,
}, {
    issueStatus: 'Resolved',
    ticketDate: '21/08/2021',
    resolutionDate: '22/08/2021',
    ticketCount: 3,
}
    , {
    issueStatus: 'Resolved',
    ticketDate: '22/08/2021',
    resolutionDate: '23/08/2021',
    ticketCount: 4,
}
    , {
    issueStatus: 'Resolved',
    ticketDate: '23/08/2021',
    resolutionDate: '24/08/2021',
    ticketCount: 2,
}
    , {
    issueStatus: 'Resolved',
    ticketDate: '24/08/2021',
    resolutionDate: '25/08/2021',
    ticketCount: 4,
}, {
    issueStatus: 'Resolved',
    ticketDate: '25/08/2021',
    resolutionDate: '25/08/2021',
    ticketCount: 1,
}
    , {
    issueStatus: 'Resolved',
    ticketDate: '25/08/2021',
    resolutionDate: '26/08/2021',
    ticketCount: 1,
}
    , {
    issueStatus: 'Canceled',
    ticketDate: '21/08/2021',
    resolutionDate: '27/08/2021',
    ticketCount: 1,
}, {
    issueStatus: 'Canceled',
    ticketDate: '22/08/2021',
    resolutionDate: '22/08/2021',
    ticketCount: 2,
}]
export class DashboardComponent extends Component {
    static displayName = DashboardComponent.name;
    constructor(props) {
        currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
        super(props);
        this.state = {
            isSubmitted: false,
            isRefreshSubmitted: false,
            loadPanelVisible: false,
            isAllDateTicket: true,
            fromDate: moment(currentTime).add(-30, "days").format("MM/DD/YYYY"),
            toDate: moment(currentTime).format("MM/DD/YYYY"),
            filterDateRangeText: moment(currentTime).add(-30, "days").format("MM/DD/YYYY") + " - " + moment(currentTime).format("MM/DD/YYYY"),
            ticketDashboardDataList: [],
            totalTicketCount: 0,
            openTicketCount: 0,
            resolvedTicketCount: 0,
            canceledTicketCount: 0,
            ticketStatusWiseSummary: [],
            ticketPeriodWiseSummary: [],
            ticketPeriodResolvedSummary: [],
            ticketPeriodCanceledSummary: [],
            errors: {
                fromDate: '',
                toDate: '',
            }
        }
    }
    async componentDidMount() {
        await this.getTicketDashboardList();
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmitted: true, loadPanelVisible: true });
            currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
            this.setState({
                isSubmitted: false,
                fromDate: moment(currentTime).add(-30, "days").format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                filterDateRangeText: moment(currentTime).add(-30, "days").format("MM/DD/YYYY") + " - " + moment(currentTime).format("MM/DD/YYYY"),
                ticketDashboardDataList: [],
                totalTicketCount: 0,
                openTicketCount: 0,
                resolvedTicketCount: 0,
                canceledTicketCount: 0,
                ticketStatusWiseSummary: [],
                ticketPeriodWiseSummary: [],
                ticketPeriodResolvedSummary: [],
                ticketPeriodCanceledSummary: [],
                errors: {
                    fromDate: '',
                    toDate: '',
                }
            });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            await this.getTicketDashboardList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmitted: false, loadPanelVisible: false });
    }
    handleOnFromDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.fromDate = '';
            let fromDate = '';
            if (event.value !== null && event.value !== '') {
                fromDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.fromDate = 'Please select date.';
            }
            if (this.state.toDate.trim() !== '') {
                errors.toDate = '';
            }
            this.setState({ fromDate: fromDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnToDateChange = (event) => {
        try {
            let errors = this.state.errors;
            errors.toDate = '';
            let toDate = '';
            if (event.value !== null && event.value !== '') {
                toDate = moment(event.value).format("MM/DD/YYYY");
            }
            else {
                //errors.toDate = 'Please select date.';
            }
            if (this.state.fromDate.trim() !== '') {
                errors.fromDate = '';
            }
            this.setState({ toDate: toDate, errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnSearchSubmit = async (event) => {
        event.preventDefault();
        try {
            let isValid = true;
            let errors = this.state.errors;
            errors.fromDate = '';
            errors.toDate = '';
            if (this.state.fromDate.trim() === '') {
                errors.fromDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.toDate.trim() === '') {
                errors.toDate = 'Please select date.';
                isValid = false;
            }
            if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                if (moment(this.state.fromDate, 'MM/DD/YYYY').isAfter(this.state.toDate, 'MM/DD/YYYY')) {
                    errors.fromDate = 'Please enter a valid date range.';
                    errors.toDate = 'Please enter a valid date range.';
                    isValid = false;
                }
            }
            if (Number(sessionStorage.getItem('session_userId')) === 0) {
                isValid = false;
                await this.onfailure('error', 'Invalid User', 'User details not found');
            }
            this.setState({ errors: errors });
            if (isValid === true) {
                this.setState({ isSubmitted: true });
                await this.getTicketDashboardList();
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false, loadPanelVisible: false });
    }
    getTicketDashboardList = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            let fromDate = '';
            let toDate = '';
            let filterDateRangeText = 'All periods';
            if (this.state.isAllDateTicket === false) {
                if (this.state.fromDate.trim() !== '' && this.state.toDate.trim() !== '') {
                    fromDate = moment(this.state.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                    toDate = moment(this.state.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD') + ' 23:59:59';
                    filterDateRangeText = moment(this.state.fromDate).format("MM/DD/YYYY") + " - " + moment(this.state.toDate).format("MM/DD/YYYY");
                }
            }
            this.setState({ filterDateRangeText: filterDateRangeText })
            let request = { FromDate: fromDate, ToDate: toDate};
            const requestParams = getRequestParams('POST', request);
            const response = await fetch('ticketing-api/dashboard-summary', requestParams);
            const data = await response.json();
            this.setState({
                //ticketDashboardDataList: ticketSummaryList
                ticketDashboardDataList: data.ticketSummary
            });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            await this.bindChart();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    bindChart = async () => {
        try {
            let ticketDashboardDataList = [];
            if (this.state.ticketDashboardDataList !== null) {
                ticketDashboardDataList = this.state.ticketDashboardDataList;
            }
            let totalTicketCount = ticketDashboardDataList.reduce((total, tickets) => total = total + tickets.ticketCount, 0);
            let openTicketCount = [...new Set(ticketDashboardDataList.filter(item => item.issueStatus === 'Open'))].reduce((total, stauts) => total = total + stauts.ticketCount, 0);
            let resolvedTicketCount = [...new Set(ticketDashboardDataList.filter(item => item.issueStatus === 'Resolved'))].reduce((total, stauts) => total = total + stauts.ticketCount, 0);
            let canceledTicketCount = [...new Set(ticketDashboardDataList.filter(item => item.issueStatus === 'Canceled'))].reduce((total, stauts) => total = total + stauts.ticketCount, 0);
            let ticketStatusWiseSummary = [];
            let ticketPeriodWiseSummary = [];

            if (ticketDashboardDataList === null || ticketDashboardDataList.length === 0) {
                ticketStatusWiseSummary.push({
                    IssueStatus: 'Ticket not found',
                    TicketCount: 0,
                });
            }
            else {
                ticketStatusWiseSummary.push({
                    IssueStatus: 'Open',
                    TicketCount: Number(openTicketCount),
                }, {
                    IssueStatus: 'Resolved',
                    TicketCount: Number(resolvedTicketCount),
                }, {
                    IssueStatus: 'Canceled',
                    TicketCount: Number(canceledTicketCount),
                });
            }

            let distinctPeriodList = [...new Set(ticketDashboardDataList.map(item => item.ticketDate))];
            for (var i = 0; i < distinctPeriodList.length; i++) {
                let ticketDate = distinctPeriodList[i];
                let pariodList = [...new Set(ticketDashboardDataList.filter(item => item.ticketDate === ticketDate))];
                let periodCount = pariodList.reduce((total, stauts) => total = total + stauts.ticketCount, 0);
                ticketPeriodWiseSummary.push({
                    TicketDate: ticketDate,
                    TicketCount: periodCount,
                });
            }
            if (ticketPeriodWiseSummary === null || ticketPeriodWiseSummary.length === 0) {
                ticketPeriodWiseSummary.push({
                    TicketDate: 'Ticket not found',
                    TicketCount: 0,
                });
            }

            let ticketPeriodResolvedSummary = [];
            let ticketPeriodCanceledSummary = [];
            let distinctResolvedPeriodList = [...new Set(ticketDashboardDataList.filter(item => item.resolutionDate !== '').map(item => item.resolutionDate))];
            for (var c = 0; c < distinctResolvedPeriodList.length; c++) {
                let resolutionDate = distinctResolvedPeriodList[c];
                let resolutionList = [...new Set(ticketDashboardDataList.filter(item => item.resolutionDate === resolutionDate))];
                let resolveCount = resolutionList.filter(item => item.issueStatus === 'Resolved').reduce((total, stauts) => total = total + stauts.ticketCount, 0);
                let canceledCount = resolutionList.filter(item => item.issueStatus === 'Canceled').reduce((total, stauts) => total = total + stauts.ticketCount, 0);
                if (Number(resolveCount) > 0) {
                    ticketPeriodResolvedSummary.push({
                        ResolutionDate: resolutionDate,
                        TicketCount: resolveCount,
                    });
                }
                if (Number(canceledCount) > 0) {
                    ticketPeriodCanceledSummary.push({
                        CanceledDate: resolutionDate,
                        TicketCount: canceledCount,
                    });
                }
            }
            if (ticketPeriodResolvedSummary === null || ticketPeriodResolvedSummary.length === 0) {
                ticketPeriodResolvedSummary.push({
                    ResolutionDate: 'Ticket not found',
                    TicketCount: 0,
                });
            }
            if (ticketPeriodCanceledSummary === null || ticketPeriodCanceledSummary.length === 0) {
                ticketPeriodCanceledSummary.push({
                    CanceledDate: 'Ticket not found',
                    TicketCount: 0,
                });
            }
            this.setState({
                totalTicketCount: totalTicketCount,
                openTicketCount: openTicketCount,
                resolvedTicketCount: resolvedTicketCount,
                canceledTicketCount: canceledTicketCount,
                ticketStatusWiseSummary: ticketStatusWiseSummary,
                ticketPeriodWiseSummary: ticketPeriodWiseSummary,
                ticketPeriodResolvedSummary: ticketPeriodResolvedSummary,
                ticketPeriodCanceledSummary: ticketPeriodCanceledSummary
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnClearSearch = async () => {
        try {
            this.setState({ loadPanelVisible: true });
            currentTime = sessionStorage.getItem("session_locationTime") === "" ? new Date() : new Date(sessionStorage.getItem("session_locationTime"));
            this.setState({
                isSubmitted: false,
                isRefreshSubmitted: false,
                isAllDateTicket: true,
                fromDate: moment(currentTime).add(-30, "days").format("MM/DD/YYYY"),
                toDate: moment(currentTime).format("MM/DD/YYYY"),
                filterDateRangeText: moment(currentTime).add(-30, "days").format("MM/DD/YYYY") + " - " + moment(currentTime).format("MM/DD/YYYY"),
                ticketDashboardDataList: [],
                totalTicketCount: 0,
                openTicketCount: 0,
                resolvedTicketCount: 0,
                canceledTicketCount: 0,
                ticketStatusWiseSummary: [],
                ticketPeriodWiseSummary: [],
                ticketPeriodResolvedSummary: [],
                ticketPeriodCanceledSummary: [],
                errors: {
                    fromDate: '',
                    toDate: '',
                }
            });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            await this.getTicketDashboardList();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 text-left">
                                <h1 className="m-0 text-dark">Dashboard</h1>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                <ol className="breadcrumb float-sm-right">
                                    <NavItem>
                                        <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" onClick={this.handleOnRefresh}>
                                            {this.state.isRefreshSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                            {!this.state.isRefreshSubmitted && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/ticketing/tickets" className="btn btn-block btn-outline-secondary btn-sm" title="View Ticket">
                                            <i className="fas fa-comment-dots"></i> View Ticket</NavLink>
                                    </NavItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="content dashboard">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="card mt-2">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <h3 className="card-title mb-0 font-weight-bold">Ticket Summary</h3>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                            <div className="form-group row">
                                                <div className="form-check col-sm-4">
                                                    <input className="form-check-input" type="checkbox"
                                                        name="isAllDateTicket"
                                                        checked={this.state.isAllDateTicket}
                                                        onChange={(e) => { this.setState({ isAllDateTicket: e.target.checked }) }} />
                                                    <label className="form-check-label" htmlFor="isAllDateTicket"> All Date (Ticket)</label>
                                                </div>
                                                <div className="col-sm-8">
                                                    <DateBox type="date"
                                                        className="form-control form-control-sm input-date"
                                                        name="fromDate"
                                                        placeholder="mm/dd/yyyy"
                                                        defaultValue={this.state.fromDate}
                                                        value={this.state.fromDate}
                                                        onValueChanged={this.handleOnFromDateChange}
                                                        width={'100%'}
                                                        acceptCustomValue={false}
                                                        disabled={this.state.isAllDateTicket}
                                                    />
                                                    {this.state.errors["fromDate"].length > 0 && <span className='error invalid-feedback'>
                                                        {this.state.errors["fromDate"]}</span>}
                                                 </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                            <div className="form-group row">
                                                <div className="form-check col-sm-4">
                                                    <label> To Date (Ticket)</label>
                                                </div>
                                                <div className="col-sm-8">
                                                    <DateBox type="date"
                                                        className="form-control form-control-sm input-date"
                                                        name="toDate"
                                                        placeholder="mm/dd/yyyy"
                                                        defaultValue={this.state.toDate}
                                                        value={this.state.toDate}
                                                        min={this.state.fromDate}
                                                        onValueChanged={this.handleOnToDateChange}
                                                        width={'100%'}
                                                        acceptCustomValue={false}
                                                        disabled={this.state.isAllDateTicket}
                                                    />
                                                    {this.state.errors["toDate"].length > 0 && <span className='error invalid-feedback'>
                                                        {this.state.errors["toDate"]}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div className="form-group mb-0">
                                                <button type="button" className="btn btn-success btn-sm" title="Search" onClick={this.handleOnSearchSubmit} >
                                                    {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                    {!this.state.isSubmitted && <span title="Search"><i className="fas fa-search"></i> Search</span>}
                                                </button>&nbsp;&nbsp;
                                                <button type="button" className="btn btn-secondary btn-sm" title="Clear"
                                                    onClick={this.handleOnClearSearch}>
                                                    <i className="fas fa-eraser"></i> Clear
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12 text-right">
                                            <span className="text-sm text-muted">{this.state.filterDateRangeText}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                            <div className="bg-info p-3 rounded-sm">
                                                <div className="media">
                                                    <i className="fas fa-ticket-alt mr-3 tickiticon mt-2"></i>
                                                    <div className="media-body">
                                                        <p className="mb-0">Total Ticket(s)</p>
                                                        <h5 className="mt-0 text-bold">{this.state.totalTicketCount}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                            <div className="bg-warning p-3 rounded-sm">
                                                <div className="media">
                                                    <i className="fas fa-ticket-alt mr-3 tickiticon mt-2"></i>
                                                    <div className="media-body">
                                                        <p className="mb-0">Open</p>
                                                        <h5 className="mt-0 text-bold">{this.state.openTicketCount}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                            <div className="bg-success p-3 rounded-sm">
                                                <div className="media">
                                                    <i className="fas fa-ticket-alt mr-3 tickiticon mt-2"></i>
                                                    <div className="media-body">
                                                        <p className="mb-0">Resolved</p>
                                                        <h5 className="mt-0 text-bold">{this.state.resolvedTicketCount}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                            <div className=" bg-danger p-3 rounded-sm">
                                                <div className="media">
                                                    <i className="fas fa-ticket-alt mr-3 tickiticon mt-2"></i>
                                                    <div className="media-body">
                                                        <p className="mb-0">Canceled</p>
                                                        <h5 className="mt-0 text-bold">{this.state.canceledTicketCount}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-between">
                                        <h3 className="card-title mb-0 font-weight-bold text-sm">Issue ticket status wise summary</h3>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Chart
                                        id="chart"
                                        palette="Soft"
                                        dataSource={this.state.ticketStatusWiseSummary}>
                                        <CommonSeriesSettings
                                            argumentField="IssueStatus"
                                            valueField="TicketCount"
                                            type="bar"
                                            ignoreEmptyPoints={true}
                                        />
                                        <SeriesTemplate nameField="IssueStatus" />
                                        {/*<Export enabled={true} />*/}
                                    </Chart>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-between">
                                        <h3 className="card-title mb-0 font-weight-bold text-sm">Issue ticket status wise summary</h3>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <PieChart
                                        id="pie"
                                        palette="Bright"
                                        dataSource={this.state.ticketStatusWiseSummary}
                                        onPointClick={this.pointClickHandler}
                                        onLegendClick={this.legendClickHandler}>
                                        <Series
                                            argumentField="IssueStatus"
                                            valueField="TicketCount">
                                            <Label
                                                visible={true}
                                                customizeText={this.customizeLabel}
                                                format={"fixedPoint"}>
                                                <Connector visible={true} width={1} />
                                            </Label>
                                        </Series>
                                        {/*<Size width={500} />*/}
                                        {/*<Export enabled={true} />*/}
                                        <Legend horizontalAlignment="right" verticalAlignment="top" />
                                    </PieChart>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-between">
                                        <h3 className="card-title mb-0 font-weight-bold text-sm">Issue ticket period wise summary</h3>
                                        <span className="text-xs text-muted">{this.state.filterDateRangeText}</span>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <PieChart
                                        id="pie"
                                        type="doughnut"
                                        palette="Soft Pastel"
                                        dataSource={this.state.ticketPeriodWiseSummary}>
                                        <Series
                                            argumentField="TicketDate"
                                            valueField="TicketCount">
                                            <Label
                                                visible={true}
                                                customizeText={this.customizeLabel}
                                                format={"fixedPoint"}>
                                                <Connector visible={true} width={1} />
                                            </Label>
                                        </Series>
                                        {/*<Export enabled={true} />*/}
                                        <Legend horizontalAlignment="right" verticalAlignment="top" />
                                    </PieChart>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-between">
                                        <h3 className="card-title mb-0 font-weight-bold text-sm">Resolved ticket period wise summary</h3>
                                        <span className="text-xs text-muted">{this.state.filterDateRangeText}</span>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <PieChart
                                        id="pie"
                                        type="doughnut"
                                        palette="Soft Pastel"
                                        dataSource={this.state.ticketPeriodResolvedSummary}>
                                        <Series
                                            argumentField="ResolutionDate"
                                            valueField="TicketCount">
                                            <Label
                                                visible={true}
                                                customizeText={this.customizeLabel}
                                                format={"fixedPoint"}>
                                                <Connector visible={true} width={1} />
                                            </Label>
                                        </Series>
                                        {/*<Export enabled={true} />*/}
                                        <Legend horizontalAlignment="right" verticalAlignment="top" />
                                    </PieChart>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-between">
                                        <h3 className="card-title mb-0 font-weight-bold text-sm">Canceled ticket period wise summary</h3>
                                        <span className="text-xs text-muted">{this.state.filterDateRangeText}</span>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <PieChart
                                        id="pie"
                                        type="doughnut"
                                        palette="Soft Pastel"
                                        dataSource={this.state.ticketPeriodCanceledSummary}>
                                        <Series
                                            argumentField="CanceledDate"
                                            valueField="TicketCount">
                                            <Label
                                                visible={true}
                                                customizeText={this.customizeLabel}
                                                format={"fixedPoint"}>
                                                <Connector visible={true} width={1} />
                                            </Label>
                                        </Series>
                                        {/*<Export enabled={true} />*/}
                                        <Legend horizontalAlignment="right" verticalAlignment="top" />
                                    </PieChart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )
    }
}