export function getRequestParams(methodType, request) {
    if (methodType === "GET") {
        const requestParams = {
            method: methodType,
            headers: {
                'Content-Type': 'application/json',
                'CultureCode': sessionStorage.getItem('session_culturecode'),
                'Authorization': 'Bearer ' + sessionStorage.getItem('session_token')
            },
        }
        return requestParams;
    } else {
        const requestParams = {
            method: methodType,
            headers: {
                'Content-Type': 'application/json',
                'CultureCode': sessionStorage.getItem('session_culturecode'),
                'Authorization': 'Bearer ' + sessionStorage.getItem('session_token')
            },
            body: JSON.stringify(request)
        }
        return requestParams;
    }
}

export function formatDate(date, dateformat) {
    if (dateformat === "MM/dd/yyyy") {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
    }

    if (dateformat === "MMM/dd/yyyy") {
        var dateM = new Date(date),
            monthM = '' + (dateM.getMonth() + 1),
            dayM = '' + dateM.getDate(),
            yearM = dateM.getFullYear();

        const newdate = new Date(yearM, monthM, dayM);  // 2009-11-10
        const monthName = newdate.toLocaleString('default', { monthM: 'short' });

        return [dayM, monthName, yearM].join(' ');
    }

    if (dateformat === "MM/dd/yyyy hh:mm") {
        var dateHM = new Date(date),
            monthHM = '' + (dateHM.getMonth() + 1),
            dayHM = '' + dateHM.getDate(),
            yearHM = dateHM.getFullYear();

        if (monthHM.length < 2)
            monthHM = '0' + monthHM;
        if (dayHM.length < 2)
            dayHM = '0' + dayHM;

        var hourHM = '' + dateHM.getHours();
        var minutesHM = '' + dateHM.getMinutes();

        if (hourHM <= 9) {
            hourHM = '0' + hourHM;
        }

        if (minutesHM <= 9) {
            minutesHM = '0' + minutesHM;
        }

        return [monthHM, dayHM, yearHM].join('/') + " " + [hourHM, minutesHM].join(':');
    }

    if (dateformat === "MM/dd/yyyy hh:mm:ss") {
        var dateHMS = new Date(date),
            monthHMS = '' + (dateHMS.getMonth() + 1),
            dayHMS = '' + dateHMS.getDate(),
            yearHMS = dateHMS.getFullYear();

        if (monthHMS.length < 2)
            monthHMS = '0' + monthHMS;
        if (dayHMS.length < 2)
            dayHMS = '0' + dayHMS;

        var hourHMS = '' + dateHMS.getHours();
        var minutesHMS = '' + dateHMS.getMinutes();
        var secondsHMS = '' + dateHMS.getSeconds();

        if (hourHMS <= 9) {
            hourHMS = '0' + hourHMS;
        }

        if (minutesHMS <= 9) {
            minutesHMS = '0' + minutesHMS;
        }

        if (secondsHMS <= 9) {
            secondsHMS = '0' + secondsHMS;
        }

        return [monthHMS, dayHMS, yearHMS].join('/') + " " + [hourHMS, minutesHMS, secondsHMS].join(':');
    }

    return [month, day, year].join('/');
}

export function formatTime(date, timeformat) {
    if (timeformat === "hh:mm") {
        let timeHM = new Date(date);
        let hourHM = '' + timeHM.getHours();
        let minutesHM = '' + timeHM.getMinutes();

        if (hourHM <= 9) {
            hourHM = '0' + hourHM;
        }
        if (minutesHM <= 9) {
            minutesHM = '0' + minutesHM;
        }
        return [hourHM, minutesHM].join(':');
    }

    if (timeformat === "hh:mm:ss") {
        let timeHMS = new Date(date);
        let hourHMS = '' + timeHMS.getHours();
        let minutesHMS = '' + timeHMS.getMinutes();
        let secondsHMS = '' + timeHMS.getSeconds();

        if (hourHMS <= 9) {
            hourHMS = '0' + hourHMS;
        }

        if (minutesHMS <= 9) {
            minutesHMS = '0' + minutesHMS;
        }

        if (secondsHMS <= 9) {
            secondsHMS = '0' + secondsHMS;
        }

        return [hourHMS, minutesHMS, secondsHMS].join(':');
    }

    let time = new Date(date);
    let hour = '' + time.getHours();
    let minutes = '' + time.getMinutes();
    let seconds = '' + time.getSeconds();
    return [hour, minutes, seconds].join(':');
}