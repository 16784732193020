import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom'; //npm install --save react-router-dom
import Swal from 'sweetalert2';
import { LoadPanel } from 'devextreme-react/load-panel';
import { getRequestParams } from '../helpers/globalfunctions.js';
export class AccountActivationComponent extends Component {
    constructor(props) {
        super(props);
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);
        this.state = {
            isSubmitted: false,
            loadPanelVisible: false,
            requestId: parsed.requestId ?? '',
            isValidRequestId: false,
            isAccountAlreadyActivated: false,
            isAccountActivated: false,
        }
    }
    async componentDidMount() {
        await this.activateAccount();
    }
    activateAccount = async () => {
        try {
            if (this.state.requestId !== null && this.state.requestId.trim().length > 0) {
                this.setState({ isValidRequestId: true });
            }
            else {
                this.setState({ isValidRequestId: false });
            }
            this.setState({ loadPanelVisible: true});
            if (this.state.requestId.trim() !== '') {
                let request = {};
                request.RequestId = this.state.requestId;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('authentication/account-confirmation', requestParams);
                if (response.ok) {
                    this.setState({ isSubmitted: true });
                    const data = await response.json();
                    if (data.isSuccess) {
                        if (data.retValue === 1) {
                            this.setState({
                                isAccountAlreadyActivated: true
                            });
                        }
                        else {
                            this.setState({
                                isAccountActivated: true
                            });
                        }
                    }
                    else {
                        this.setState({ isValidRequestId: false });
                        await this.showMessage('error', null, data.resultMessage);
                    }
                }
                else {
                    this.setState({ isValidRequestId: false });
                    await this.showMessage('error', null, null);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    showMessage = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    render() {
        return (
            <div>
                <div className="forgot-password-page">
                    <div className="forgot-password-box">
                        <div className="forgot-password-logo">
                            <NavLink><img src="../../dist/img/logo_utwiz.png" alt="UTWiz Ticketing" /></NavLink>
                        </div>
                        <div className="card">
                            <div className="card-body login-card-body">
                                <h4 className="login-box-msg"><strong>Account Activation</strong></h4>
                                <p className="login-box-msg" style={{ display: (this.state.isSubmitted && this.state.isAccountActivated) || 'none' }}>
                                    <span className='text-lg text-success font-weight-bold'>{'The account is activated successfully'}</span>&nbsp;&nbsp;
                                    <a href="/login" title="Sign In">Sign In</a>
                                </p>
                                <p className="login-box-msg" style={{ display: (this.state.isSubmitted && this.state.isAccountAlreadyActivated) || 'none' }}>
                                    <span className='text-lg text-danger font-weight-bold'>{'The account is already activated.'}</span>&nbsp;&nbsp;
                                    <a href="/login" title="Sign In">Sign In</a>
                                </p>
                                <p className="login-box-msg" style={{ display: (!this.state.isValidRequestId) || 'none' }}>
                                    <span className='text-lg text-danger font-weight-bold'>{'The request is not valid'}</span>
                                </p>
                                <div className="mb-3" >
                                    <NavLink className="text-center" tag={Link} to="/" title="Go back to login"><i className="mb-0 text-center"></i>Go back to login</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div>
        )
    }
}
