import React, { Component } from 'react';
import { NavMenu } from './NavMenu';

export class AuthorisedLayout extends Component {
    static displayName = AuthorisedLayout.name;

    render() {
        return (
            <div>
                <div className="wrapper">
                    <NavMenu />
                    {this.props.children}
                </div>
                <footer className="main-footer">
                    <strong>Copyright &copy; 2020 <a href="https://www.utwiz.com/" target="_blank" title="UTWiz Ticketing"><img src="../../dist/img/logo_utwiz_dark.png" alt="UTWiz Ticketing" width="52" /></a>.</strong> All rights reserved.
                </footer>
            </div>
        );
    }
}