import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams } from '../helpers/globalfunctions.js';
import Swal from 'sweetalert2';
import { post } from 'axios'; // npm install --save axios
import ReactHtmlParser from 'html-react-parser' //npm i html-react-parser;
import HtmlEditor, { Toolbar, Item } from 'devextreme-react/html-editor';
const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
export class TicketCommentComponent extends Component {
    constructor(props) {
        super(props)
        let requestId = '';
        let allowReply = true;
        let allowClose = false;
        let isFromTicketWeb = false;
        if (this.props.location.state) {
            requestId = (!this.props.location.state) ? '' : this.props.location.state.requestId;
            allowReply = (!this.props.location.state) ? false : this.props.location.state.allowReply;
            isFromTicketWeb = (!this.props.location.state) ? false : this.props.location.state.isFromTicketWeb;
            allowClose = (!this.props.location.state) ? false : this.props.location.state.allowClose;
        }
        else {
            const queryString = require('query-string');
            var parsed = queryString.parse(this.props.location.search);
            requestId = parsed.requestId ?? '';
        }
        this.state = {
            isSubmitted: false,
            isRefreshSubmitted: false,
            loadPanelVisible: false,
            requestId: requestId,
            allowReply: allowReply,
            isFromTicketWeb: isFromTicketWeb,
            allowClose: allowClose,
            ticket: {
                ticketNo: '',
                userFullName: '',
                userPhone: '',
                userEmail: '',
                helpTopic: '',
                priority: '',
                issueStatusId: 1,
                status: '',
                issueBy: '',
                dateofOccurrence: '',
                locationName: '',
                resolvedBy: '',
                resolvedDate: '',
                isReplyClosed: false,
                issueSummary: '',
                issueDetails: '',
                attachments: [],
                comments: [],
            },
            ticketId: 0,
            issueStatusDropdownList: [],
            issueStatusId: 0,
            comment: '',
            attachments: [],
            errors: {
                issuestatus: '',
                comment: ''
            }
        }
    }
    async componentDidMount() {
        await this.fetchApiData();
    }
    async fetchApiData() {
        try {
            this.setState({ loadPanelVisible: true });
            await this.getTicketComments();
            await this.getTicketDropdownStatus();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    getTicketComments = async () => {
        try {
            if (this.state.requestId === null || this.state.requestId === '') {
                await this.showMessage('error', 'Invalid request', null);
            }
            else {
                const requestParams = getRequestParams('GET');
                const response = await fetch('ticketing-api/comments/' + this.state.requestId, requestParams);
                if (response.ok) {
                    const responseData = await response.json();
                    if (responseData.isSuccess) {
                        let ticket = this.state.ticket;
                        ticket.ticketNo = responseData.ticket.ticketNo;
                        ticket.userFullName = responseData.ticket.userFullName;
                        ticket.userPhone = responseData.ticket.userPhone;
                        ticket.userEmail = responseData.ticket.userEmail;
                        ticket.helpTopic = responseData.ticket.helpTopic;
                        ticket.priority = responseData.ticket.priority;
                        ticket.issueStatusId = responseData.ticket.issueStatusId;
                        ticket.status = responseData.ticket.status;
                        ticket.issueBy = responseData.ticket.issuedBy;
                        ticket.dateofOccurrence = this.convertUTCToLocal(responseData.ticket.dateofOccurrence);
                        ticket.locationName = responseData.ticket.locationName;
                        ticket.resolvedBy = responseData.ticket.resolvedBy;
                        ticket.resolvedDate = this.convertUTCToLocal(responseData.ticket.resolutionDate);
                        ticket.isReplyClosed = responseData.ticket.isReplyClosed;
                        ticket.issueSummary = responseData.ticket.issueSummary;
                        ticket.issueDetails = responseData.ticket.issueDetails;
                        ticket.attachments = responseData.ticket.attachments;
                        ticket.comments = (responseData.comments !== null) ? responseData.comments.filter(el => { el.commentDate = this.convertUTCToLocal(el.commentDate); return el; }) : responseData.comments;                        
                        this.setState({ ticket: ticket, ticketId: responseData.ticket.id, issueStatusId: responseData.ticket.issueStatusId });
                    }
                    else {
                        await this.showMessage('error', null, responseData.resultMessage);
                    }
                }
                else {
                    await this.showMessage('error', null, null);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    convertUTCToLocal = (utcDateTime) => {
        try {
            if (utcDateTime !== '') {
                if (!utcDateTime.toLowerCase().includes('utc')) {
                    utcDateTime = `${utcDateTime} UTC`;
                }
                utcDateTime = new Date(utcDateTime).toLocaleString();
            }
            return utcDateTime;
        } catch (ex) {
            console.log(ex);
            return '';
        }
    }
    handleOnRefresh = async () => {
        try {
            this.setState({ isRefreshSubmitted: true });
            this.setState({
                isSubmitted: false,
            });
            await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
            await this.fetchApiData();
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isRefreshSubmitted: false });
    }
    // Start ticket view comment
    handleOnFileSelect = async (event) => {
        try {
            this.setState({ loadPanelVisible: true });
            let attachments = this.state.attachments;
            //let fileCount = attachments.length + event.target.files.length;
            //if (fileCount > 5) {
            //    await this.showMessage('error', 'Max file limit', 'Please select no more than 5 files.');
            //}
            //else
            if (event.target.files.length > 0) {
                let isFilesInvalid = false;
                let documentSelectedFile = event.target.files;
                const formData = new FormData();
                for (var i = 0; i < documentSelectedFile.length; i++) {
                    let file = documentSelectedFile[i];
                    if (file.size > 0) {
                        formData.append('File', file);
                    }
                    else {
                        isFilesInvalid = true;
                        await this.showMessage('error', 'Invalid file', 'The file ' + file.name + ' found invalid.');
                        break;
                    }
                }
                if (isFilesInvalid === false) {
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data',
                            'UserId': sessionStorage.getItem('session_userId'),
                            'Authorization': 'Bearer ' + sessionStorage.getItem('session_token'),
                            'CultureCode': sessionStorage.getItem('session_culturecode'),
                        },
                    };
                    const response = await post('ticketing-api/add-attachment', formData, config);
                    if (response.status === 200) {
                        const data = await response.data.attachments;
                        if (data !== null) {
                            let maxFileId = 0;
                            if (attachments !== null && attachments.length > 0) {
                                maxFileId = Math.max.apply(Math, attachments.map(function (val) { return val.id; }))
                            }
                            for (var a = 0; a < data.length; a++) {
                                maxFileId = Number(maxFileId) + 1;
                                let attachment = {};
                                attachment.id = maxFileId;
                                attachment.fileName = data[a].fileName;
                                attachment.fileData = data[a].fileData;
                                attachment.fileSize = data[a].fileSize;
                                attachment.fileExtension = data[a].fileExtension;
                                attachments.push(attachment);
                                this.setState({ attachments: attachments });
                            }
                        }
                    }
                }
            }
            else {
                await this.showMessage('error', 'Invalid file', 'Please select file again.');
            }
            this.fileComment.value = "";
        } catch (ex) {
            await this.showMessage('error', null, null);
            console.log(ex);
        }
        this.setState({ loadPanelVisible: false });
    }
    removeAttachmante = async (e, id) => {
        e.preventDefault();
        try {
            let attachments = this.state.attachments;
            attachments = attachments.filter(r => r.id !== id);
            this.setState({ attachments: attachments });
        } catch (ex) {
            console.log(ex);
        }
    }
    getTicketDropdownStatus = async () => {
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch('ticketing-api/issuestatus-dropdown', requestParams);
            const data = await response.json();
            this.setState({
                issueStatusDropdownList: data.issueStatus
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCommentBodyChange = (e) => {
        try {
            let errors = this.state.errors;
            errors.comment = '';
            this.setState({ comment: e.value, errors: errors })
        } catch (ex) {
            console.log(ex);
        }
    }
    removeHtmlTags = (str) => {
        try {
            if ((str === null) || (str === ''))
                return '';
            else
                str = str.toString();
            // Regular expression to identify HTML tags in 
            // the input string. Replacing the identified 
            // HTML tag with a null string.
            return str.replace(/(<([^>]+)>)/ig, '');
        } catch (ex) {
            console.log(ex);
            return '';
        }
    }
    handleOnSaveCommentSubmitted = async event => {
        event.preventDefault();
        try {
            let isFormValid = true;
            let errors = this.state.errors;
            errors.comment = '';
            errors.issuestatus = '';
            //if (this.state.comment.getEditorState().getCurrentContent().getPlainText().trim() === '') {
            if (this.removeHtmlTags(this.state.comment.trim()).trim() === '') {
                isFormValid = false;
                errors.comment = "Please enter comment.";
            }
            if (this.state.allowClose) {
                if (Number(this.state.issueStatusId) === 0) {
                    isFormValid = false;
                    errors.issuestatus = "Please select status.";
                }
            }
            if (isFormValid === true) {
                this.setState({ isSubmitted: true });
                let request = {};
                request.TicketId = Number(this.state.ticketId);
                request.IssueStatusId = Number(this.state.issueStatusId);
                request.ParentCommentId = 0;
                request.IsSystemComment = false;
                request.Comment = this.state.comment.trim();
                request.Attachments = this.state.attachments;
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('ticketing-api/save-comment', requestParams);
                if (response.ok) {
                    const data = await response.json();
                    await Swal.fire({
                        allowOutsideClick: false,
                        icon: data.isSuccess ? 'success' : 'error',
                        title: data.isSuccess ? 'Success' : 'Oops...',
                        html: (data.isSuccess === false && (data.resultMessage === null || data.resultMessage === "")) ? 'Unable to process the request.' : data.resultMessage,
                    }).then(async (result) => {
                        if (data.isSuccess) {
                            if (result.isConfirmed) {
                                await this.fetchApiData();
                                await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
                                let errors = this.state.errors;
                                errors.comment = '';
                                errors.issuestatus = '';
                                this.setState({
                                    issueStatusId: this.state.ticket.issueStatusId,
                                    comment: '',
                                    attachments: [],
                                    errors: errors
                                });
                            }
                        }
                    })
                }
                else {
                    await this.showMessage('error', 'Failed!', null);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    handleOnClearComment = async event => {
        event.preventDefault();
        try {
            let errors = this.state.errors;
            errors.comment = '';
            errors.issuestatus = '';
            this.setState({
                isSubmitted: false,
                issueStatusId: this.state.ticket.issueStatusId,
                comment: '',
                attachments: [],
                errors: errors
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnShowAttachment = async (e, attachmentId, isCommentAttachment, commentId) => {
        e.preventDefault();
        try {
            let attachments = [];
            if (isCommentAttachment === true) {
                if (this.state.ticket.comments !== null) {
                    let comments = this.state.ticket.comments.filter(c => c.id === commentId);
                    if (comments !== null) {
                        attachments = comments[0].attachments;
                    }
                }
            }
            else {
                attachments = this.state.ticket.attachments;
            }
            if (attachments !== null) {
                let attachment = attachments.filter(a => a.id === attachmentId)
                if (attachment !== null) {
                    let a = document.createElement('a');
                    a.href = attachment[0].fileData;
                    a.download = attachment[0].fileName;
                    a.click();
                    //const requestParams = getRequestParams('GET');
                    //const response = await fetch('ticketing-api/file-mimetype/' + attachment[0].fileExtension, requestParams);
                    //if (response.ok) {
                    //    const data = await response.json();
                    //    if (data.isSuccess === true) {
                    //        let a = document.createElement('a');
                    //        a.href = data.resultMessage + attachment[0].fileData;
                    //        a.download = attachment[0].fileName;
                    //        a.click();
                    //    }
                    //    else {
                    //        await this.showMessage('error', 'Invalid File', null);
                    //    }
                    //}
                }
            }
            else {
                await this.showMessage('error', 'File Not Found', null);
            }
        } catch (ex) {
            await this.showMessage('error', 'Download Failed!', null);
            console.log(ex);
        }
    }
    // End ticket view comment
    showMessage = async (icon, title, resultMessage) => {
        this.setState({ loadPanelVisible: false });
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    render() {
        return (
            <div>
                <form method="post" onSubmit={this.handleOnGenerateSubmit}
                    style={(this.state.isSubmitted || this.state.isRefreshSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <h1 className="m-0 text-dark">Ticket Comments</h1>
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                        <ol className="breadcrumb float-sm-right">
                                            <NavItem>
                                                <NavLink className="btn btn-block btn-outline-secondary btn-sm" tag={Link} to="#" onClick={this.handleOnRefresh}>
                                                    {this.state.isRefreshSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                    {!this.state.isRefreshSubmitted && <span title="Refresh"><i className="fas fa-sync-alt"></i> Refresh</span>}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem style={{ display: (this.state.isFromTicketWeb) || 'none' }}>
                                                <NavLink tag={Link} to="/ticketing/tickets" className="btn btn-block btn-outline-secondary btn-sm" title="Ticket">
                                                    <i className="fas fa-comment-dots"></i> View Ticket</NavLink>
                                            </NavItem>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="card mt-2" style={{ width: "75%", margin: "0 auto" }}>
                                <div class="card-header tickitheader p-2">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-left">
                                            <div className="form-group mt-1">
                                                <a href="/"><img src="../../dist/img/logo_utwiz.png" alt="UTWiz Ticketing" className="brandlogosm" /></a>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 text-right" >
                                            <div className="form-group">
                                                <span className="badge badge-pill titckitbadge p-2"
                                                    style={{
                                                        backgroundColor: (this.state.ticket.isReplyClosed && this.state.ticket.issueStatusId === 2) ? '#28a745' :
                                                            (this.state.ticket.isReplyClosed && this.state.ticket.issueStatusId === 3) ? "#dc3545" : "#ffc107"
                                                        , position: "absolute", right: '20px'
                                                        , color: (this.state.ticket.isReplyClosed && this.state.ticket.issueStatusId === 2) ? '#FFFFFF' :
                                                            (this.state.ticket.isReplyClosed && this.state.ticket.issueStatusId === 3) ? "#FFFFFF" : "#121212"
                                                    }}>
                                                    Status: {this.state.ticket.status}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label>Name: <strong>{this.state.ticket.userFullName}</strong></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label>Phone: <strong>{this.state.ticket.userPhone}</strong></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label>Email: <strong>{this.state.ticket.userEmail}</strong></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label>Ticket No: <strong>{this.state.ticket.ticketNo}</strong></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label>Help Topic: <strong>{this.state.ticket.helpTopic}</strong></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label>Priority: <strong><span className="badge badge-pill badge-warning"
                                                    style={{ fontSize: '12px' }}>{this.state.ticket.priority}</span>
                                                </strong>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label>Issue By: <strong>{this.state.ticket.issueBy}</strong></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label>Occurrence Date: <strong>{this.state.ticket.dateofOccurrence}</strong></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label>City: <strong>{this.state.ticket.locationName}</strong></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6" style={{ display: (this.state.ticket.isReplyClosed) || 'none' }}>
                                            <div className="form-group">
                                                <label>Resolved By: <strong>{this.state.ticket.resolvedBy}</strong></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" style={{ display: (this.state.ticket.isReplyClosed) || 'none' }}>
                                            <div className="form-group">
                                                <label>Resolved Date: <strong>{this.state.ticket.resolvedDate}</strong></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-3 col-sm-6 col-xs-12">
                                            <div className="form-group">
                                                <label>Issue Summary: <strong>{this.state.ticket.issueSummary}</strong></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div style={{
                                                border: '1px solid transparent', boxShadow: '0 1px 1px rgb(0 0 0 /5%)', borderRadius: '4px'
                                                , borderColor: '#dddddd', marginBottom: '15px'
                                            }}>
                                        <Comment
                                                    isSystemReply={false}
                                                    userName={this.state.ticket.userFullName}
                                                    commentDate={this.state.ticket.dateofOccurrence}
                                                    //status={this.state.ticket.status}
                                                    status={'Open'} // This is master status , it is updated each time on comment  keep showing open as status because this section showing ticket issue details as comment
                                                    comment={this.state.ticket.issueDetails}
                                                    attachments={this.state.ticket.attachments}
                                                    isCommentAttachment={false}
                                                    commentId={0}
                                                    handleOnShowAttachment={this.handleOnShowAttachment}/>
                                        {
                                            this.state.ticket.comments &&
                                                    this.state.ticket.comments.map((comment, i) =>
                                                        <Comment
                                                            key={i}
                                                            isSystemReply={comment.isSystemReply}
                                                            userName={comment.commentBy}
                                                            commentDate={comment.commentDate}
                                                            status={comment.status}
                                                            comment={comment.issueDetails}
                                                            attachments={comment.attachments}
                                                            isCommentAttachment={true}
                                                            commentId={comment.id}
                                                            handleOnShowAttachment={this.handleOnShowAttachment}
                                                            isReplyClosed={this.state.ticket.isReplyClosed}
                                                            issueStatusId={comment.issueStatusId}
                                                />
                                            )
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: (!this.state.ticket.isReplyClosed || (Number(this.state.ticketId) === 0)) || 'none' }}>
                                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" style={{ display: (this.state.allowClose) || 'none' }}>
                                            <div className="form-group">
                                                <label>Status <span className="text-danger">*</span></label>
                                                <select name="ddissuestatus" className="form-control form-control-sm"
                                                    disabled={(this.state.ticket.isReplyClosed || (!this.state.allowReply) || (Number(this.state.ticketId) === 0))}
                                                    value={this.state.issueStatusId} onChange={(e) => {
                                                        let errors = this.state.errors;
                                                        errors.issuestatus = '';
                                                        this.setState({
                                                            issueStatusId: e.target.value, errors: errors
                                                        })
                                                    }}>
                                                    <option value="0">---Select issue status---</option>
                                                    {
                                                        this.state.issueStatusDropdownList &&
                                                        this.state.issueStatusDropdownList.map((item, i) =>
                                                            (<option key={i} value={item.id}>{item.name}</option>))
                                                    }
                                                </select>
                                                {this.state.errors["issuestatus"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["issuestatus"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label>Comment <span className="text-danger">*</span></label>
                                                <HtmlEditor
                                                    height={120}
                                                    name="comment"
                                                    value={this.state.comment}
                                                    valueType={"html"}
                                                    onValueChanged={this.handleOnCommentBodyChange}
                                                    disabled={(this.state.ticket.isReplyClosed || (!this.state.allowReply) || (Number(this.state.ticketId) === 0))}
                                                >
                                                    <Toolbar>
                                                        <Item name="undo" />
                                                        <Item name="redo" />
                                                        <Item name="separator" />
                                                        <Item
                                                            name="size"
                                                            acceptedValues={sizeValues}
                                                        />
                                                        <Item
                                                            name="font"
                                                            acceptedValues={fontValues}
                                                        />
                                                        <Item name="separator" />
                                                        <Item name="bold" />
                                                        <Item name="italic" />
                                                        <Item name="strike" />
                                                        <Item name="underline" />
                                                        <Item name="separator" />
                                                        <Item name="alignLeft" />
                                                        <Item name="alignCenter" />
                                                        <Item name="alignRight" />
                                                        <Item name="alignJustify" />
                                                        <Item name="separator" />
                                                        <Item name="color" />
                                                        <Item name="background" />
                                                    </Toolbar>
                                                </HtmlEditor>
                                                {this.state.errors["comment"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["comment"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <div className="custom-file-upload">
                                                    <button type="button"
                                                        className="btn btn-secondary btn-sm"
                                                        onClick={() => { this.fileComment.click() }}
                                                        disabled={(this.state.ticket.isReplyClosed || (!this.state.allowReply) || (Number(this.state.ticketId) === 0))}
                                                    ><i class="fas fa-paperclip"></i>&nbsp;&nbsp; Choose File</button>&nbsp;&nbsp;
                                                    <span>
                                                        {this.state.attachments.length === 0 ? 'No file attached' : this.state.attachments.length + ' file(s) attached'
                                                        }
                                                    </span>
                                                    <input type="file" className="custom-file-input"
                                                        ref={ref => this.fileComment = ref}
                                                        name="Files" multiple
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => { this.handleOnFileSelect(e, true) }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-2" style={{ display: (!this.state.ticket.isReplyClosed || (Number(this.state.ticketId) === 0)) || 'none' }}>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                {
                                                    this.state.attachments &&
                                                    this.state.attachments.map((item, i) =>
                                                    (<label style={{ marginRight: '2px' }}
                                                        key={i}>{item.fileName}
                                                        <NavLink onClick={(e) => { this.removeAttachmante(e, item.id) }}>
                                                            <span style={{ color: 'red', fontSize: '20px', cursor: 'pointer', marginRight: '6px', position: 'relative', top: '-8px' }}>
                                                                ×</span></NavLink>
                                                    </label>
                                                    )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right">
                                            <div className="form-group">
                                                <button type="button" className="btn btn-success btn-sm" title="Save Comment"
                                                    disabled={(this.state.ticket.isReplyClosed || !this.state.allowReply || (Number(this.state.ticketId) === 0))}
                                                    onClick={this.handleOnSaveCommentSubmitted}>
                                                    {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                    {!this.state.isSubmitted && <span title="Save Comment"><i className="fas fa-comment-dots"></i> Save Comment</span>}
                                                </button>&nbsp;&nbsp;
                                                <button type="button" className="btn btn-custom-gray btn-sm" title="Clear"
                                                    disabled={(this.state.ticket.isReplyClosed || !this.state.allowReply || (Number(this.state.ticketId) === 0))}
                                                    onClick={this.handleOnClearComment}>
                                                    <i className="fas fa-eraser"></i> Clear
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '#panelgrid' }}
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </div >
        )
    }
}
const systemCommentBlock = {
    backgroundColor: '#f1f1f1', borderRadius: '0px', borderColor: '#d6e9c6', padding: '0px', color: '#111111',
};
const userCommentBlock = {
    backgroundColor: '#FFFFFF', borderRadius: '0px', borderColor: '#ffffff', padding: '0px', color: '#111111',
};
const commentBlackHeaderColor = issueStatusId => {
    let bgcolor = issueStatusId === 2 ? '#c1ffcf' : issueStatusId === 3 ? '#ffa9a9' : '#fff5d1';
    return { backgroundColor: bgcolor, borderRadius: '0px', borderColor: '#d6e9c6', padding: '4px', color: '#111111' }
};

function Comment(props) {
    return (
        <div style={(props.isSystemReply === true) ? systemCommentBlock : userCommentBlock}>
            <div style={commentBlackHeaderColor(props.issueStatusId)}>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 messagehead">
                        &nbsp;&nbsp;{props.userName}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
                        <small> {props.commentDate}</small> &nbsp;
                        
                        <span className="badge badge-pill badge-dark"
                            style={{
                                backgroundColor: (props.isReplyClosed && props.issueStatusId === 2) ? '#28a745' :
                                    (props.isReplyClosed && props.issueStatusId === 3) ? "#dc3545" : "#ffc107"
                                ,  right: '20px'
                                , color: (props.isReplyClosed && props.issueStatusId === 2) ? '#FFFFFF' :
                                    (props.isReplyClosed && props.issueStatusId === 3) ? "#FFFFFF" : "#121212"
                            }}>
                            {props.status}
                        </span>
                        &nbsp;&nbsp;
                    </div>
                </div>
            </div>
            <hr className="messagehrdevider" />
            <div className="form-group mb-0 messagedeviderpara" style={{ padding: '10px'}}>
                {ReactHtmlParser(props.comment)}
            </div>
            <div style={{
                display: (props.attachments &&
                    props.attachments.length > 0) || 'none',
                padding: '10px 15px',borderTop: '1px solid #ddd',
                borderBottomLeftRadius: '3px', borderBottomRightRadius: '3px'
            }}>
                {
                    props.attachments &&
                    props.attachments.map((item, i) =>
                    (<NavLink className="text-xs messageattachelink" style={{ marginRight: '10px' }}
                        key={i}
                        onClick={(e) => { props.handleOnShowAttachment(e, item.id, props.isCommentAttachment, props.commentId) }}>
                        <i className="fas fa-paperclip"
                            style={{ color: '#000000', marginRight: '2px' }}>
                        </i>{item.fileName}
                    </NavLink>
                    )
                    )
                }
            </div>
            <hr className="messagehrdevider" />
        </div>
    );
}