import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import Swal from 'sweetalert2';
import jsSHA from "jssha"; // npm install jssha
import { formatDate } from './helpers/globalfunctions.js';
export class LoginComponent extends Component {
    static displayName = LoginComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: false,
            userName: '',
            password: '',
            message: '',
            showPasswordEye: false,
            errors: {
                userName: '',
                password: ''
            }
        }
    }
    handleOnInputChange = (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'userName':
                    errors.userName = '';
                    if (value.length <= 50) {
                        this.setState({ userName: value });
                    }
                    break;
                case 'password':
                    errors.password = '';
                    if (value.length <= 50) {
                        this.setState({ password: value });
                    }
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnClear = async () => {
        try {
            this.setState({
                isSubmitted: false,
                userName: '',
                password: '',
                message: '',
                errors: {
                    userName: '',
                    password: ''
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    calcHash(value, securityKey) {
        let shaObj = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
        shaObj.update(value);
        let saltText = shaObj.getHash("HEX");
        let shaObj2 = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
        shaObj2.update(saltText + securityKey);
        return shaObj2.getHash("HEX");
    }
    handleOnLoginSubmitted = async (event) => {
        event.preventDefault();
        try {
            let isFormValid = true;
            let errors = this.state.errors;
            errors.userName = '';
            errors.password = '';
            if (this.state.userName.trim() === '') {
                isFormValid = false;
                errors.userName = "Please enter username or email.";
            }
            if (this.state.password.trim() === '') {
                isFormValid = false;
                errors.password = "Please enter password.";
            }
            this.setState({ message: '', errors: errors });
            if (isFormValid === true) {
                this.setState({ isSubmitted: true });
                let passwordHash = "";
                const tokenRequestParams = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ UserName: this.state.userName })
                }
                const tokenResponse = await fetch('authentication/password-token-request', tokenRequestParams);
                if (tokenResponse.ok) {
                    const tokenData = await tokenResponse.json();
                    if (tokenData.isSuccess === true) {
                        let token = tokenData.resultMessage;
                        passwordHash = this.calcHash(this.state.password, token);

                        const requestParams = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ UserName: this.state.userName, Password: passwordHash })
                        }
                        const response = await fetch('authentication/user', requestParams);
                        if (response.ok) {
                            const data = await response.json();
                            if (data.isSuccess === true) {
                                sessionStorage.setItem("session_token", data.token);
                                sessionStorage.setItem("session_userId", data.userContext.userId);
                                sessionStorage.setItem("session_userName", data.userContext.userName);
                                sessionStorage.setItem("session_password", data.userContext.password);
                                sessionStorage.setItem("session_name", data.userContext.fullName);
                                sessionStorage.setItem("session_email", data.userContext.email);
                                sessionStorage.setItem("session_phone", data.userContext.mobileNo);
                                sessionStorage.setItem("session_userTypeId", data.userContext.userTypeId);
                                sessionStorage.setItem("session_userTypeName", data.userContext.userTypeName);
                                sessionStorage.setItem("session_accountId", data.userContext.accountId);
                                sessionStorage.setItem("session_accountname", data.userContext.accountName);
                                sessionStorage.setItem("session_accountcode", data.userContext.accountCode);
                                sessionStorage.setItem("session_locationId", data.userContext.locationId);
                                sessionStorage.setItem("session_locationName", data.userContext.locationName);
                                sessionStorage.setItem("session_minutesDifference", data.userContext.minutesDifference);
                                sessionStorage.setItem("session_fileGuid", data.userContext.fileGuid);
                                sessionStorage.setItem("session_culturecode", "en-US");

                                let offset = parseInt(data.userContext.minutesDifference);
                                let currentDate = new Date();
                                var istDateTime = new Date(currentDate.getTime() + (60000 * (currentDate.getTimezoneOffset() + offset)));
                                let locationdate = formatDate(istDateTime, "MM/dd/yyyy hh:mm:ss");
                                sessionStorage.setItem('session_locationTime', locationdate);

                                window.location.href = "/dashboard";
                            }
                            else {
                                this.setState({ message: 'User authentication failed!', isSubmitted: false });
                                await this.onfailure('error', 'User authentication failed!', (data.resultMessage === '' || data.resultMessage === null) ? 'Subscription not available.' : data.resultMessage);
                            }
                        }
                        else {
                            this.setState({ message: 'Authorization failed!', isSubmitted: false });
                            await this.onfailure('error', 'Authorization failed!', null);
                        }
                    } else {
                        this.setState({ message: 'User authentication failed!', isSubmitted: false });
                        await this.onfailure('error', 'User authentication failed!', null);
                    }
                }
                else {
                    this.setState({ message: 'Authentication failed!', isSubmitted: false });
                    await this.onfailure('error', 'Authentication failed!', null);
                }
            }
        } catch (ex) {
            this.setState({ isSubmitted: false });
            await this.onfailure('error', null, null);
            console.log(ex);
        }
    }
    onfailure = async (icon, title, resultMessage) => {
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    render() {
        return (
            <div className="login-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 mt-5">
                            <div classname="captionsidebar">
                                <h3 className="captionsidebarheader">Get Your Tickets Online</h3>
                                <p className="captionsidebarcontent">Get Onboard Quickly: Book Train & Bus Tickets Online<br />For Check Your Tickit Status
                                    <NavLink tag={Link} className="linktxt p-1" title="Check tickit status" to="/ticketing/ticket/status">Click here</NavLink>
                                    <br /><br />
                                    <NavLink tag={Link} className="btn loginbtn p-2" to="/ticketing/ticket/create">Open a New Ticket</NavLink>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="card card-login">
                                <div className="card-body">
                                    <div className="login-logo mb-0"><img src="../../dist/img/logo_utwiz_dark.png" alt="UTWiz Ticketing" /></div>
                                    <p className="login-box-msg">Sign in to your account!</p>
                                    <form method="post" onSubmit={this.handleOnLoginSubmitted} style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-user"></i></span>
                                                    </div>
                                                    <input type="text" className="input form-control"
                                                        name="userName" placeholder="Username / Email"
                                                        value={this.state.userName}
                                                        onChange={this.handleOnInputChange} />
                                                    {this.state.errors["userName"].length > 0 && <span className='error invalid-feedback'>
                                                        {this.state.errors["userName"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-lock"></i></span>
                                                    </div>
                                                    <input type={this.state.showPasswordEye ? "text" :"password" } className="input form-control"
                                                        name="password" placeholder="Password"
                                                        value={this.state.password}
                                                        onChange={this.handleOnInputChange} />
                                                    <div className="input-group-append">
                                                        <span style={{cursor:"pointer"}} className="input-group-text" onClick={() => { this.setState({ showPasswordEye: !this.state.showPasswordEye }) }}>
                                                            <i className="fas fa-eye iconpass" hidden={this.state.showPasswordEye}></i>
                                                            <i className="fas fa-eye-slash iconpass" hidden={!this.state.showPasswordEye}></i>
                                                        </span>
                                                    </div>
                                                    {this.state.errors["password"].length > 0 && <span className='error invalid-feedback'>
                                                        {this.state.errors["password"]}</span>}
                                                    {this.state.message.length > 0 && <span className='error invalid-feedback'>{this.state.message}</span>}
                                                </div>
                                                <div className="form-group text-right">
                                                    <button type="submit" className="btn btn-success btn-sm" title="Login">
                                                        {this.state.isSubmitted && <span title="Login"><i className="fas fa-spinner fa-spin"></i> Login</span>}
                                                        {!this.state.isSubmitted && <span title="Login"><i className="fas fa-sign-in-alt"></i> Login</span>}
                                                    </button>&nbsp;&nbsp;&nbsp;
                                                    <button type="button" className="btn btn-secondary btn-sm" title="Clear"
                                                        onClick={this.handleOnClear}>
                                                        <i className="fas fa-eraser"></i> Clear
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div><NavLink tag={Link} to="/account/forgot-password" title="I forgot my password"
                                                        className="text-sm p-0 mb-1">I forgot my password</NavLink>
                                                    </div>
                                                    <div><NavLink tag={Link} to="/account/registration" title="Register a new membership"
                                                        className="text-sm p-0 mb-0">Register a new membership</NavLink>
                                                    </div>
                                                </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
