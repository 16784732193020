import React, { Component } from 'react';
import { formatDate } from '../helpers/globalfunctions';
export class ClockComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentlocationdate: formatDate(new Date(), "MM/dd/yyyy hh:mm:ss"),
        }

        this.getLocationTime = this.getLocationTime.bind(this);
        this.getEstOffset = this.getEstOffset.bind(this);
        this.getEstOffset1 = this.getEstOffset1.bind(this);
    }

    componentDidMount() {
        this.interval = setInterval(this.getLocationTime, 1000);
        //this.getLocationTime();
    }

    getLocationTime() {
        try {
            let offset = parseInt(sessionStorage.getItem("session_minutesDifference"));
            let currentDate = new Date();
            var istDateTime = new Date(currentDate.getTime() + (60000 * (currentDate.getTimezoneOffset() + offset)));
            const estOffset = this.getEstOffset(istDateTime);

            if (offset.toString() === '-600'
                || offset.toString() === '330' // Mumbai
                || offset.toString() === '240' // UAE
                || offset.toString() === '480' // China Standard Time , Central Indonesia Time
                || offset.toString() === '300' // MVT)
            ) {
                // No Daylight apply
            }
            else {
                if (estOffset === -4) {
                    istDateTime = new Date(istDateTime.getTime() + (60000 * 60));
                }
            }
            let locationdate = formatDate(istDateTime, "MM/dd/yyyy hh:mm:ss");
            sessionStorage.setItem('session_locationTime', locationdate);
            this.setState({ currentlocationdate: locationdate });

            //const d = new Date();
            //const localTime = d.getTime();
            ////const localOffset = d.getTimezoneOffset() * 60 * 1000;
            //const localOffset = offset * 60 * 1000;
            //const utcTime = localTime + localOffset;
            //const estOffset1 = this.getEstOffset1();
            //const usa = utcTime + (60 * 60 * 1000 * estOffset1);

            //// convert msec value to date string
            //const nd = new Date(usa)
            //let locationdate1 = formatDate(nd, "MM/dd/yyyy hh:mm:ss");
            //sessionStorage.setItem('session_locationTime', locationdate1);
            //this.setState({ currentlocationdate: locationdate1 });            

        } catch (e) {
        }
    }

    getEstOffset1() {
        const stdTimezoneOffset = () => {
            var jan = new Date(0, 1)
            var jul = new Date(6, 1)
            return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
        }

        var today = new Date()

        const isDstObserved = (today) => {
            return today.getTimezoneOffset() < stdTimezoneOffset()
        }

        if (isDstObserved(today)) {
            return -4
        } else {
            return -5
        }
    }

    getEstOffset(locDateTime) {
        const stdTimezoneOffset = () => {
            var jan = new Date(0, 1)
            var jul = new Date(6, 1)
            return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
        }

        var today = new Date(locDateTime);

        const isDstObserved = (today) => {
            return today.getTimezoneOffset() < stdTimezoneOffset()
        }

        if (isDstObserved(today)) {
            return -4
        } else {
            return -5
        }
    }

    render() {
        return (
            <div className="clock">
                <div>
                    {this.state.currentlocationdate}
                    {/*{this.props.date.toLocaleTimeString(this.props.country, { hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', seconds: '2-digit', timeZone: this.props.timeZone })}*/}
                </div>
            </div>
        );
    }
}