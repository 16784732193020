import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { AuthorisedLayout } from './components/AuthorisedLayout';
import { LoginComponent } from './components/LoginComponent';
import { ForgotPasswordComponent } from './components/ResetPassword/ForgotPasswordComponent'
import { ResetPasswordComponent } from './components/ResetPassword/ResetPasswordComponent'
import { AccountRegistrationComponent } from './components/Account/AccountRegistrationComponent'
import { AccountActivationComponent } from './components/Account/AccountActivationComponent'
import { DashboardComponent } from './components/DashboardComponent';
import { TicketingComponent } from './components/Ticketing/TicketingComponent'
import { TicketCommentComponent } from './components/Ticketing/TicketCommentComponent'
import { CreateNewTicketComponent } from './components/Ticketing/CreateNewTicketComponent'
import { TicketStatusComponent } from './components/Ticketing/TicketStatusComponent'
import './custom.css'
export default class App extends Component {
    static displayName = App.name;

    render() {
        const isAuthorized = (sessionStorage.getItem('session_token') === '' || sessionStorage.getItem('session_token') === null) ? false : true;
        if (isAuthorized === true) {
            document.querySelector('body').classList.remove('login-page');
        }
        return (
            <div>{
                isAuthorized === true ?
                    <AuthorisedLayout>
                        <Route exact path='/' component={DashboardComponent} />
                        <Route path='/dashboard' component={DashboardComponent} />
                        <Route path='/ticketing/tickets' component={TicketingComponent} />
                        <Route path='/ticketing/ticket/comment' component={TicketCommentComponent} />
                    </AuthorisedLayout>
                    :
                    <Layout>
                        <Route exact path='/' component={LoginComponent} />
                        <Route path='/login' component={LoginComponent} />
                        <Route path='/account/forgot-password' component={ForgotPasswordComponent} />
                        <Route path='/account/reset-password' component={ResetPasswordComponent} />
                        <Route path='/account/registration' component={AccountRegistrationComponent} />
                        <Route path='/account/confirmation' component={AccountActivationComponent} />
                        <Route path='/ticketing/ticket/create' component={CreateNewTicketComponent} />
                        <Route path='/ticketing/ticket/comment' component={TicketCommentComponent} />
                        <Route path='/ticketing/ticket/status' component={TicketStatusComponent} />
                    </Layout>
            }
            </div>
        );
    }
}
