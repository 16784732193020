import React, { Component } from 'react';
import Modal from 'react-modal'; //npm install --save react-modal
import Swal from 'sweetalert2';
import jsSHA from "jssha"; // npm install jssha
import { NavLink } from 'reactstrap';
import { getRequestParams } from '../helpers/globalfunctions.js';
export class ChangePasswordComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isValidPassword: false,
            isSubmitted: false,
            isOpenModal: false,
            isPasswordUpdated: false,
            ticketUserId: 0,
            userName: '',
            oldPassword: '',
            password: '',
            confirmPasswrod: '',
            oldPwdType: 'password',
            pwdType: 'password',
            confirmPwdType: 'password',
            errors: {
                oldPassword: '',
                password: '',
                confirmPasswrod: '',
            }
        }
    }
    showModal = async () => {
        try {
            this.setState({ isOpenModal: true })
            this.setState({ ticketUserId: sessionStorage.getItem('session_userId'), userName: sessionStorage.getItem('session_userName') })
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnCloseModal = async () => {
        try {
            this.setState({
                isValidPassword: false,
                isSubmitted: false,
                isOpenModal: false,
                isPasswordUpdated: false,
                ticketUserId: 0,
                userName: '',
                oldPassword: '',
                password: '',
                confirmPasswrod: '',
                oldPwdType: 'passsword',
                pwdType: 'passsword',
                confirmPwdType: 'passsword',
                errors: {
                    oldPassword: '',
                    password: '',
                    confirmPasswrod: '',
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'oldPassword':
                    errors.oldPassword = '';
                    if (value.length < 20) {
                        this.setState({ oldPassword: value });
                    }
                    break;
                case 'password':
                    errors.password = '';
                    if (value.length < 20) {
                        this.setState({ password: value });
                        await this.isValidPasswordInput(value);
                    }
                    break;
                case 'confirmPasswrod':
                    errors.confirmPasswrod = '';
                    if (value.length < 20) {
                        this.setState({ confirmPasswrod: value });
                        await this.isValidPasswordInput(value);
                    }
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    isValidPasswordInput = async (password) => {
        let isValid = false;
        try {
            var elementpwdlength = document.getElementById("pwdlength");
            var elementpwdlowercase = document.getElementById("pwdlowercase");
            var elementpwduppercase = document.getElementById("pwduppercase");
            var elementpwdnumbersymbol = document.getElementById("pwdnumbersymbol");
            var elementspecialcharacters = document.getElementById("specialcharacters");

            const renumbersymbolexp = /[0-9]/;
            const lowercaseexp = /[a-z]/;
            const uppercaseexp = /[A-Z]/;
            const specialcharacters = /[!@#$%^&*]/;

            var IsPwdnumbersymbol = renumbersymbolexp.test(password);
            var IsLowercase = lowercaseexp.test(password);
            var IsUppercase = uppercaseexp.test(password);
            var IsSpecialCharacters = specialcharacters.test(password);

            if (password.length > 8) {
                elementpwdlength.classList.remove("text-muted");
                elementpwdlength.classList.add("text-success");
            }
            else {
                elementpwdlength.classList.remove("text-success");
                elementpwdlength.classList.add("text-muted");
            }

            if (IsPwdnumbersymbol) {
                elementpwdnumbersymbol.classList.remove("text-muted");
                elementpwdnumbersymbol.classList.add("text-success");
            }
            else {
                elementpwdnumbersymbol.classList.remove("text-success");
                elementpwdnumbersymbol.classList.add("text-muted");
            }

            if (IsLowercase) {
                elementpwdlowercase.classList.remove("text-muted");
                elementpwdlowercase.classList.add("text-success");
            }
            else {
                elementpwdlowercase.classList.remove("text-success");
                elementpwdlowercase.classList.add("text-muted");
            }

            if (IsUppercase) {
                elementpwduppercase.classList.remove("text-muted");
                elementpwduppercase.classList.add("text-success");
            }
            else {
                elementpwduppercase.classList.remove("text-success");
                elementpwduppercase.classList.add("text-muted");
            }

            if (IsSpecialCharacters) {
                elementspecialcharacters.classList.remove("text-muted");
                elementspecialcharacters.classList.add("text-success");
            }
            else {
                elementspecialcharacters.classList.remove("text-success");
                elementspecialcharacters.classList.add("text-muted");
            }

            if (password === "") {
                elementpwdlength.classList.remove("text-success");
                elementpwdlength.classList.add("text-muted");
                elementpwdnumbersymbol.classList.remove("text-success");
                elementpwdnumbersymbol.classList.add("text-muted");
                elementpwdlowercase.classList.remove("text-success");
                elementpwdlowercase.classList.add("text-muted");
                elementpwduppercase.classList.remove("text-success");
                elementpwduppercase.classList.add("text-muted");
                elementspecialcharacters.classList.remove("text-success");
                elementspecialcharacters.classList.add("text-muted");
            }

            if (password.length > 8 && IsPwdnumbersymbol === true && IsLowercase === true && IsUppercase === true && IsPwdnumbersymbol === true && IsSpecialCharacters === true) {
                this.setState({ isValidPassword: true, isValid: true });
            } else {
                this.setState({ isValidPassword: false, isValid: false });
            }
        } catch (ex) {
            console.log(ex);
        }
        return isValid;
    }
    handleOnOldPwdShowHide = async (e) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                oldPwdType: this.state.oldPwdType === 'input' ? 'password' : 'input'
            })
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnPwdShowHide = async (e) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                pwdType: this.state.pwdType === 'input' ? 'password' : 'input',
            })
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnConfirmPwdShowHide = async (e) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                confirmPwdType: this.state.confirmPwdType === 'input' ? 'password' : 'input'
            })
        } catch (ex) {
            console.log(ex);
        }
    }
    calcHash(value, securityKey) {
        let shaObj = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
        shaObj.update(value);
        let saltText = shaObj.getHash("HEX");
        let shaObj2 = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
        shaObj2.update(saltText + securityKey);
        return shaObj2.getHash("HEX");
    }
    handleOnResetPasswordSubmit = async event => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                let passwordHash = "";
                const tokenRequestParams = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ UserName: this.state.userName })
                }
                const tokenResponse = await fetch('authentication/password-token-request', tokenRequestParams);
                if (tokenResponse.ok) {
                    const tokenData = await tokenResponse.json();
                    if (tokenData.isSuccess === true) {
                        let token = tokenData.resultMessage;
                        passwordHash = this.calcHash(this.state.oldPassword, token);
                        const authRequestParams = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ UserName: this.state.userName, Password: passwordHash })
                        }
                        const authResponse = await fetch('authentication/user', authRequestParams);
                        if (authResponse.ok) {
                            const authData = await authResponse.json();
                            if (authData.isSuccess === true) {
                                let request = {};
                                request.Password = this.state.password;
                                request.Email = '';
                                request.TicketUserId = Number(this.state.ticketUserId);
                                const requestParams = getRequestParams('POST', request);
                                const response = await fetch('authentication/reset-user-account-password', requestParams);
                                const data = await response.json();
                                if (data.isSuccess) {
                                    this.setState({ isPasswordUpdated: true });
                                    let successMsg = (data.resultMessage === '' || data.resultMessage === null) ? 'This user password has been reset successfully.' : data.resultMessage;
                                    await Swal.fire({
                                        allowOutsideClick: false,
                                        icon: 'success',
                                        title: 'Success',
                                        html: successMsg,
                                    }).then(async (result) => {
                                        if (data.isSuccess) {
                                            if (result.isConfirmed) {
                                                await this.handleOnCloseModal();
                                                sessionStorage.clear();
                                                window.location.href = "/";
                                            }
                                        }
                                    });
                                }
                                else {
                                    await this.showMessage('error', null, null);
                                }
                            }
                            else {
                                await this.showMessage('error', 'User authentication failed!', 'Faild to authenticate user ' + this.state.userName);
                            }
                        }
                    }
                    else {
                        await this.showMessage('error', 'User authentication failed!', 'Faild to authenticate user ' + this.state.userName);
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.oldPassword = '';
            errors.password = '';
            errors.confirmPasswrod = '';
            if (this.state.oldPassword.trim() === '') {
                isValid = false;
                errors.oldPassword = "Please enter old password.";
            }
            if (this.state.password.trim() === '') {
                isValid = false;
                errors.password = "Please enter password.";
            }
            if (this.state.confirmPasswrod.trim() === '') {
                isValid = false;
                errors.confirmPasswrod = "Please re-enter password.";
            }
            if (this.state.password.trim() !== '' && this.state.confirmPasswrod.trim() !== '') {
                if (this.state.password.trim() !== this.state.confirmPasswrod.trim()) {
                    errors.confirmPasswrod = 'Password does not match, Re-enter password again.';
                    isValid = false;
                }
            }
            if (this.state.oldPassword === this.state.password) {
                isValid = false;
                errors.password = "Old and new password must not be same.";
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    showMessage = async (icon, title, resultMessage) => {
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    render() {
        return (
            <div>
                <Modal ariaHideApp={false}
                    isOpen={this.state.isOpenModal}
                    style={{ content: { width: '30%' } }}
                    className={"react-modal"}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Example Modal">
                    <form method="post" onSubmit={this.handleOnResetPasswordSubmit} style={this.state.isSubmitted ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                        <div className="modal-header">
                            <h6 className="modal-title">Change Password</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleOnCloseModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="text-center mb-1">You forgot your password? Here you can easily retrieve a new password.</p>
                            <p className="text-center"><small>You are going to change the password for <i><strong className="text-danger">{this.state.userName}</strong></i> as user name.</small></p>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Old Password <span className="text-red">*</span></label><label className="mb-1 float-right">
                                            <NavLink onClick={this.handleOnOldPwdShowHide}>{this.state.oldPwdType === 'input' ? 'Hide Password' : 'Show Password'}</NavLink>
                                        </label>
                                        <input type={this.state.oldPwdType}
                                            className="form-control form-control-sm"
                                            name="oldPassword"
                                            title="Old Password"
                                            placeholder="Enter old password"
                                            value={this.state.oldPassword}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["oldPassword"].length > 0 && <span className="error invalid-feedback">{this.state.errors["oldPassword"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>New Password <span className="text-red">*</span></label><label className="mb-1 float-right">
                                            <NavLink onClick={this.handleOnPwdShowHide}>{this.state.pwdType === 'input' ? 'Hide Password' : 'Show Password'}</NavLink>
                                        </label>
                                        <input type={this.state.pwdType}
                                            className="form-control form-control-sm"
                                            name="password"
                                            title="New Password"
                                            placeholder="Enter new password"
                                            value={this.state.password}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["password"].length > 0 && <span className="error invalid-feedback">{this.state.errors["password"]}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label>Re-type New Password <span className="text-red">*</span></label><label className="mb-1 float-right">
                                            <NavLink onClick={this.handleOnConfirmPwdShowHide}>{this.state.confirmPwdType === 'input' ? 'Hide Password' : 'Show Password'}</NavLink>
                                        </label>
                                        <input type={this.state.confirmPwdType}
                                            className="form-control form-control-sm"
                                            name="confirmPasswrod"
                                            title="Re-type New Password"
                                            placeholder="Re enter new password"
                                            value={this.state.confirmPasswrod}
                                            onChange={this.handleOnInputChange} />
                                        {this.state.errors["confirmPasswrod"].length > 0 && <span className="error invalid-feedback">{this.state.errors["confirmPasswrod"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <p className="mb-0"><small id="pwdlength" className="text-muted"><i className="fas fa-check-circle"></i> At least 8 characters long</small></p>
                                        <p className="mb-0"><small id="pwdlowercase" className="text-muted"><i className="fas fa-check-circle"></i> One lowercase character</small></p>
                                        <p className="mb-0"><small id="pwduppercase" className="text-muted"><i className="fas fa-check-circle"></i> One uppercase character</small></p>
                                        <p className="mb-0"><small id="pwdnumbersymbol" className="text-muted"><i className="fas fa-check-circle"></i> One number, symbol, or whitespace character</small></p>
                                        <p className="mb-0"><small id="specialcharacters" className="text-muted"><i className="fas fa-check-circle"></i> One special character</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer text-center">
                            <button type="submit"
                                className="btn btn-success btn-sm"
                                disabled={(!this.state.isValidPassword || this.state.isPasswordUpdated)}
                                title="Change Password">
                                <i className="fas fa-sign-in-alt"></i> Change Password
                            </button>
                            <button type="button" className="btn btn-secondary btn-sm" onClick={this.handleOnCloseModal} title="Close"><i className="fas fa-times"></i> Close</button>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
}