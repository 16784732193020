import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom'; //npm install --save react-router-dom
import { getRequestParams } from '../helpers/globalfunctions.js';
import Swal from 'sweetalert2';
export class TicketStatusComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: false,
            isRequestSent: false,
            isValidTicketNo: false,
            userEmail: '',
            ticketNo: '',
            errors: {
                userEmail: '',
                ticketNo: ''
            }
        }
    }
    handleOnInputChange = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            const value = (target.type === 'checkbox' || target.type === 'radio') ? target.checked : target.value;
            let errors = this.state.errors;
            switch (name) {
                case 'userEmail':
                    errors.userEmail = '';
                    if (value.length < 51) {
                        this.setState({ userEmail: value })
                    }
                    break;
                case 'ticketNo':
                    errors.ticketNo = '';
                    if (value.trim().length < 9) {
                        this.setState({ ticketNo: value.toUpperCase(), isValidTicketNo: false });
                        if (value.trim().length === 8) {
                            this.setState({ isValidTicketNo: true });
                        }
                    }
                    break;
                default:
                    break;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnInputLeave = async (event) => {
        try {
            const target = event.target;
            const name = target.name;
            if (name === 'ticketNo') {
                let ticketNo = this.state.ticketNo.trim();
                if (ticketNo.length === 8) {
                    this.setState({ isValidTicketNo: true });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnPaste = async (event) => {
        try {
            let ticketNo = event.clipboardData.getData('Text').trim();
            if (ticketNo.length === 10) {
                ticketNo = ticketNo.substring(2, ticketNo.length);
                this.setState({ ticketNo: ticketNo, isValidTicketNo: true });
            }
            if (ticketNo.length === 8) {
                this.setState({ ticketNo: ticketNo, isValidTicketNo: true });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    checkAccountExists = async (userNameOrEmail) => {
        let isValid = false;
        try {
            let errors = this.state.errors;
            errors.userEmail = '';
            if (userNameOrEmail.trim() !== '') {
                let request = {};
                request.Email = userNameOrEmail;
                request.Password = '';
                const requestParams = getRequestParams('POST', request);
                const response = await fetch('authentication/verify-user-account', requestParams);
                const data = await response.json();
                if (data.isSuccess === false && data.retValue === -1) {
                    errors.userEmail = userNameOrEmail + ' account does not exist';
                }
                isValid = data.isSuccess;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            console.log(ex);
        }
        return isValid;
    }
    handleOnSendTicketStatusSubmit = async event => {
        event.preventDefault();
        try {
            if (await this.isFormValid()) {
                this.setState({ isSubmitted: true });
                if (await this.checkAccountExists(this.state.userEmail)) {
                    let request = {};
                    request.TicketNo = 'TK' + this.state.ticketNo;
                    const requestParams = getRequestParams('POST', request);
                    const response = await fetch('ticketing-api/send-ticket-accesslink', requestParams);
                    const data = await response.json();
                    if (data.isSuccess) {
                        let successMsg = (data.resultMessage === '' || data.resultMessage === null) ? 'Ticket access request has been submitted successfully and will be notified shortly.' : data.resultMessage;
                        this.setState({ isRequestSent: true });
                        await Swal.fire({
                            allowOutsideClick: false,
                            icon: 'success',
                            title: 'Success',
                            html: successMsg,
                        }).then(async (result) => {
                            if (data.isSuccess) {
                                if (result.isConfirmed) {
                                    window.location.href = "/";
                                    sessionStorage.clear();
                                }
                            }
                        });
                    }
                    else {
                        await this.showMessage('error', null, data.resultMessage);
                    }
                }
                else {
                    let errors = this.state.errors;
                    errors.userEmail = this.state.userEmail + ' account does not exist.';
                    this.setState({ errors: errors });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitted: false });
    }
    isFormValid = async () => {
        let isValid = true;
        try {
            let errors = this.state.errors;
            errors.userEmail = '';
            errors.ticketNo = '';
            let regEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (this.state.userEmail.trim() === '') {
                isValid = false;
                errors.userEmail = "Please enter username or email.";
            }
            if (this.state.userEmail.trim() !== '') {
                let isEmail = (this.state.userEmail.indexOf('@') > -1)
                if (isEmail) {
                    if (!regEmail.test(String(this.state.userEmail.trim()).toLowerCase())) {
                        isValid = false;
                        errors.userEmail = "Please enter valid email.";
                    }
                }
            }
            if (this.state.ticketNo.trim() === '') {
                errors.ticketNo = 'Please enter ticket no.';
                isValid = false;
            }
            if (this.state.ticketNo.trim() !== '' && this.state.ticketNo.trim().length !== 8) {
                errors.ticketNo = 'Please enter 8 characters for each ticket.';
                isValid = false;
            }
            this.setState({ errors: errors });
        } catch (ex) {
            isValid = false;
            console.log(ex);
        }
        return isValid;
    }
    showMessage = async (icon, title, resultMessage) => {
        await Swal.fire({
            allowOutsideClick: false,
            showCloseButton: false,
            icon: icon,
            title: (title === null || title.trim() === '') ? 'Oops...' : title,
            html: (resultMessage === null || resultMessage.trim() === '') ? 'Opps! something went wrong, unable to process the request.' : resultMessage.trim(),
        })
    }
    render() {
        return (
            <div className="login-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 mt-5">
                            <div classname="captionsidebar">
                                <h3 className="captionsidebarheader">Check Your Current Ticket Status</h3>
                                <p className="captionsidebarcontent">Enter the Tickit Number for your booking to get the current status.</p>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12 mt-5"></div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="card card-forgotpassword">
                                <div className="card-body">
                                    <div className="forgot-password-logo">
                                        <a href="/"><img src="../../dist/img/logo_utwiz_dark.png" alt="UTWiz Ticketing" /></a>
                                    </div>
                                    <p className="text-center text-lg text-green font-weight-bold mb-0">Check Ticket Status</p>
                                    <p className="login-box-msg">We are here to help you. Enter the email or username and a ticket number and we'll send you an access link.</p>
                                    <form method="post" onSubmit={this.handleOnSendTicketStatusSubmit}
                                        style={(this.state.isSubmitted) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-user iconpass"></i></span>
                                                    </div>
                                                    <input type="text" className="form-control form-control-sm"
                                                        name="userEmail"
                                                        placeholder="Username / Email"
                                                        title="Username / Email"
                                                        value={this.state.userEmail}
                                                        onChange={this.handleOnInputChange} />
                                                    {this.state.errors["userEmail"].length > 0 && <span className='error invalid-feedback'>{this.state.errors["userEmail"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon1"> <i className="fas fa-text iconpass">TK</i></span>
                                                    </div>
                                                    <input type="text" className="form-control form-textbox form-control-sm"
                                                        name="ticketNo" title="Ticket No" placeholder="Ticket No"
                                                        value={this.state.ticketNo}
                                                        onChange={this.handleOnInputChange}
                                                        onBlur={this.handleOnInputLeave}
                                                        onPaste={this.handleOnPaste}
                                                         />
                                                    {this.state.isValidTicketNo
                                                        && <span style={{ position: 'absolute', top: '0px', right: '17px', color: 'green' }}>
                                                            <i className="fas fa-check"></i> </span>}
                                                    
                                                    {this.state.errors["ticketNo"].length > 0 && <span className='error invalid-feedback'>
                                                        {this.state.errors["ticketNo"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" >
                                                <div className="form-group mb-3">
                                                    <button type="submit" className="btn btn-success btn-block"
                                                        title="Email Access Link"
                                                        disabled={this.state.isRequestSent}>
                                                        {this.state.isSubmitted && <span title="In-process"><i className="fas fa-spinner fa-spin"></i> In-process</span>}
                                                        {!this.state.isSubmitted && <span title="Email Access Link"><i className="fas fa-paper-plane"></i> Email Access Link</span>}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" >
                                                <NavLink className="text-center" tag={Link} to="/" title="Go back to login"><i className="mb-0 text-center"></i>Go back to login</NavLink>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
