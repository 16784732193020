import React, { Component } from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { getRequestParams } from './helpers/globalfunctions.js';
import { ClockComponent } from '../components/Shared/ClockComponent';
import { ChangePasswordComponent } from '../components/Shared/ChangePasswordComponent';
export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            userProfileImage: '../../dist/img/avatar.png',
            isSignout: false,
        };
    }
    async componentDidMount() {
        await this.bindProfileImage();
    }
    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    handleOnSignout = (event) => {
        event.preventDefault();
        try {
            this.setState({ isSignout: true })
            window.location.href = "/";
            sessionStorage.clear();
        } catch (ex) {
            console.log(ex);
        }
    }
    handleOnShowChangePasswordModal = (event) => {
        event.preventDefault();
        try {
            this.refs.changePasswordModal.showModal();
        } catch (ex) {
            console.log(ex);
        }
    }
    bindProfileImage = async () => {
        try {
            var fileGuid = sessionStorage.getItem('session_fileGuid');
            if (fileGuid !== null && fileGuid !== '00000000-0000-0000-0000-000000000000') {
                const requestParams = getRequestParams('GET');
                const response = await fetch('authentication/user-profile-photo/' + fileGuid, requestParams);
                const data = await response.json();
                this.setState({ userProfileImage: data.resultMessage });
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    render() {
        const isAuthorized = (sessionStorage.getItem('session_token') === '' || sessionStorage.getItem('session_token') === null) ? false : true;
        return (
            <header>
                <div>
                    <div>
                        {/* <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                            <Container>
                                <NavbarBrand tag={Link} to="/"><img src="../../dist/img/logo_utwiz.png" alt="UTWiz Ticketing" /></NavbarBrand>
                                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                                    <ul className="navbar-nav flex-grow">
                                        {
                                            isAuthorized === true ||
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/">Open a New Ticket</NavLink>
                                            </NavItem>
                                        }
                                        {
                                            isAuthorized === true ||
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/counter">Check Ticket Status</NavLink>
                                            </NavItem>
                                        }
                                        {
                                            isAuthorized === false ||
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/ticketing/tickets">View Ticket</NavLink>
                                            </NavItem>
                                        }
                                        {isAuthorized === false ||
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="#" onClick={this.handleOnSignout}>Sign out</NavLink>
                                            </NavItem>
                                        }
                                        {
                                            isAuthorized === false ||
                                            <NavItem>
                                                <NavLink tag={Link} to="#"><ClockComponent /></NavLink>
                                            </NavItem>
                                        }
                                    </ul>
                                </Collapse>
                            </Container>
                        </Navbar>*/}
                    </div>
                    <nav className="main-header navbar navbar-expand-md navbar-light navbar-white" style={{ marginLeft: '0px' }}>
                        <div className="container" style={{ maxWidth: '100%' }}>
                            <a href="/dashboard" className="navbar-brand" title="Dashboard" >
                                <img src="../../dist/img/logo_utwiz.png" alt="UTWiz Ticketing" className="brandlogo" />
                            </a>

                            <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse order-3" id="navbarCollapse">
                                <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
                                    {

                                        isAuthorized === false ||
                                        <NavItem>
                                            <NavLink tag={Link} to="#">{this.state.isSignout === false && <ClockComponent />}</NavLink>
                                        </NavItem>

                                    }
                                    {
                                        isAuthorized === true ||
                                        <NavItem>
                                            <NavLink tag={Link} to="/ticketing/ticket/create">Open a New Ticket</NavLink>
                                        </NavItem>
                                    }
                                    {
                                        isAuthorized === true ||
                                        <NavItem>
                                            <NavLink tag={Link} to="/ticketing/ticket/status">Check Ticket Status</NavLink>
                                        </NavItem>
                                    }
                                    {
                                        isAuthorized === true ||
                                        <NavItem>
                                            <NavLink tag={Link} to="/login">Sign In</NavLink>
                                        </NavItem>
                                    }
                                    {
                                        isAuthorized === false ||
                                        <NavItem>
                                            <NavLink tag={Link} to="/dashboard">Dashboard</NavLink>
                                        </NavItem>
                                    }
                                    {
                                        isAuthorized === false ||
                                        <NavItem>
                                            <NavLink tag={Link} to="/ticketing/tickets">View Ticket</NavLink>
                                        </NavItem>
                                    }

                                    {
                                        isAuthorized === false ||
                                        <li className="nav-item dropdown">
                                            <NavLink className="nav-link" data-toggle="dropdown">
                                                <i className="fas fa-user-circle"></i>&nbsp;&nbsp;
                                                {sessionStorage.getItem('session_name').substring(0, 20)}&nbsp;&nbsp;<i className="fas fa-arrow-down">
                                                </i>
                                            </NavLink>
                                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right user-menu">
                                                <i className="dropdown-item"></i>
                                                <div className="media">
                                                    <img alt="User Avatar"
                                                        src={this.state.userProfileImage} className="img-size-50 mr-3 img-circle" />
                                                    <div className="media-body">
                                                        {/*<h3 className="dropdown-item-title text-info">
                                                           Username: <b>{sessionStorage.getItem('session_userName')}</b>
                                                        </h3>*/}
                                                        <p className="dropdown-item text-sm"><i class="fas fa-user"></i>  <b className="text-info">{sessionStorage.getItem('session_userName')}</b></p>
                                                        <p className="dropdown-item text-sm"><i class="fas fa-envelope"></i>  <b className="text-info">{sessionStorage.getItem('session_email')}</b></p>
                                                    </div>
                                                </div>

                                                <div className="dropdown-divider"></div>
                                                <NavLink className="dropdown-item" onClick={(e) => { this.handleOnShowChangePasswordModal(e) }}><i class="fas fa-key"></i> Change Password</NavLink>
                                                <hr className="mt-0 mb-0" />
                                                <a href="http://support.utwiz.com/" target="new" className="dropdown-item"><i class="fas fa-question"></i> &nbsp; Help Desk</a>
                                                <div className="dropdown-divider"></div>
                                                <span className="dropdown-item"><a href="/" className="text-dark" onClick={this.handleOnSignout}><i class="fas fa-sign-out-alt"></i> Sign out</a>
                                                    <a target="new" href="https://www.utwiz.com/privacy/" className="float-right text-sm text-dark">Privacy Policy</a>
                                                </span>
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </nav >
                    <ChangePasswordComponent ref="changePasswordModal" />
                </div>
            </header>
        );
    }
}
